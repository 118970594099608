<template>
  <v-container v-if="error">
    <v-alert
      border="start"
      type="error"
      text
    >
      {{ error }}
    </v-alert>
  </v-container>
</template>

<script setup lang="ts">
import { mapGetters } from "vuex";
import { createNewQueryComponent } from "@/renderer/displayComponent";
import { gunzipSync } from "zlib";
import { dartFunctions } from "@/renderer/kusto_queries";
import { eventBus } from "@/services/eventBus";
import { QueryTemplate } from "@/renderer/kusto_queries";
import { Buffer } from "buffer";

import { ref, computed, onMounted } from 'vue';


// Data
const error = ref(null);

// Computed

// Mounted
onMounted(() => {
    const title = "Tag Row";
    const e = this.$route.query?.e;
    const colName = this.$route.query?.colName;
    const timeField = this.$route.query?.t;
    const engagement = e ? e : this.getEngagement;
    const bdata = this.$route.query?.r;
    let zdata = Buffer.from(bdata, "base64");
    const jdata = gunzipSync(zdata).toString("utf8");
    const row = JSON.parse(jdata);
    const rowVals = [];
    const dateChk = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}T");
    for (const [k, v] of Object.entries(row)) {
      if (typeof v === "string" && dateChk.test(v)) {
        rowVals.push(`['${k}']=datetime(${v})`);
      } else if (typeof v === "number" || typeof v === "boolean") {
        rowVals.push(`['${k}']=${v}`);
      } else {
        const v0 = v ? v : "";
        rowVals.push(`['${k}']=\`\`\`${v0}\`\`\``);
      }
    }
    const tags = [
      "engagement:" + engagement,
      "cluster:" + this.getCluster,
      "database:" + this.getDatabase,
    ];
    const extendEventTime = timeField
      ? ` | extend EventTime = ${timeField}, TimestampType='${timeField}'`
      : "";
    const printExpr = "print " + rowVals.join(", ");
    const hashIdExpr = colName
      ? ` | extend HashId = column_ifexists('HashId', ${colName})`
      : "";
    const query = `${dartFunctions}\n${printExpr}${extendEventTime}${hashIdExpr} | invoke addTaggingFields('')`;
    const queryTemplate = new QueryTemplate({
      query: query,
      functions: [],
    });
    const mQuery = queryTemplate.buildQuery({ columnId: colName });
    const uuid = createNewQueryComponent(
      title,
      mQuery,
      this.getCluster,
      this.getDatabase,
      [],
      true,
    );
    eventBus.$emit("action:tag-all", { uuid: uuid, tags: tags });
  })
//export default {
//  name: "TagRow",
//  data: () => ({
//    error: null,
//  }),
//  computed: {
//    ...mapGetters("engagement", ["getCluster", "getDatabase", "getEngagement"]),
//  },
//  async mounted() {
//    const title = "Tag Row";
//    const e = this.$route.query?.e;
//    const colName = this.$route.query?.colName;
//    const timeField = this.$route.query?.t;
//    const engagement = e ? e : this.getEngagement;
//    const bdata = this.$route.query?.r;
//    let zdata = Buffer.from(bdata, "base64");
//    const jdata = gunzipSync(zdata).toString("utf8");
//    const row = JSON.parse(jdata);
//    const rowVals = [];
//    const dateChk = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}T");
//    for (const [k, v] of Object.entries(row)) {
//      if (typeof v === "string" && dateChk.test(v)) {
//        rowVals.push(`['${k}']=datetime(${v})`);
//      } else if (typeof v === "number" || typeof v === "boolean") {
//        rowVals.push(`['${k}']=${v}`);
//      } else {
//        const v0 = v ? v : "";
//        rowVals.push(`['${k}']=\`\`\`${v0}\`\`\``);
//      }
//    }
//    const tags = [
//      "engagement:" + engagement,
//      "cluster:" + this.getCluster,
//      "database:" + this.getDatabase,
//    ];
//    const extendEventTime = timeField
//      ? ` | extend EventTime = ${timeField}, TimestampType='${timeField}'`
//      : "";
//    const printExpr = "print " + rowVals.join(", ");
//    const hashIdExpr = colName
//      ? ` | extend HashId = column_ifexists('HashId', ${colName})`
//      : "";
//    const query = `${dartFunctions}\n${printExpr}${extendEventTime}${hashIdExpr} | invoke addTaggingFields('')`;
//    const queryTemplate = new QueryTemplate({
//      query: query,
//      functions: [],
//    });
//    const mQuery = queryTemplate.buildQuery({ columnId: colName });
//    const uuid = await createNewQueryComponent(
//      title,
//      mQuery,
//      this.getCluster,
//      this.getDatabase,
//      [],
//      true,
//    );
//    eventBus.$emit("action:tag-all", { uuid: uuid, tags: tags });
//  },
//};
</script>

<style></style>
