<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >{{ editFunction ? 'Edit' : 'Create' }} Function</span
        >
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form
            ref="form"
            :disabled="func.isManaged === true"
          >
            <v-row v-if="func.isManaged === true">
              <p class="text-red-lighten-3">
                This function is being managed by source control at
                <a
                  href="https://microsoft.visualstudio.com/Windows%20Defender/_git/WD.StrategicHunt.Tim.Queries"
                  target="_blank"
                  class="text-red-lighten-3"
                  >TIM Functions</a
                >
              </p>
            </v-row>
            <v-row>
              <v-text-field
                v-model="func.name"
                label="Name"
                variant="filled"
                required
                :rules="[(v) => !!v || 'Name is required']"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="func.description"
                label="Description"
                required
              />
            </v-row>
            <v-row v-if="func.query || func.query === ''">
              <p>Query</p>
              <KustoMonacoEditor
                name="functionEditor"
                :value="func.query"
                style="
                  width: 100%;
                  height: 200px;
                  border: 1px dotted darkgrey;
                  margin-bottom: 20px;
                "
                language="kusto"
                :options="{ readOnly: func.isManaged === true }"
              />
            </v-row>
          </v-form>
          <v-row>
            <v-alert
              v-if="creationError"
              type="error"
              style="width: 100%; margin-top: 20px"
            >
              {{ creationError }}
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          :disabled="func.isManaged === true"
          @click="onCreateFunction()"
        >
          {{ editFunction ? 'Save' : 'Create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import KustoMonacoEditor from '@/components/KustoMonacoEditor.vue';
import { functionCreate, functionUpdate } from '@/services/apiClient';
import { eventBus } from '@/main';
import { generateUuidv4 } from '@/renderer/utils';
import { VDialog } from 'vuetify/components';

import { reactive, onMounted, ref, onBeforeMount } from 'vue';

// Data
const func = reactive({
  name: '',
  description: '',
  query: '',
  isManaged: false,
  uuid: '',
});

const creationError = ref<string>('');
// Props
const props = defineProps({
  dialog: {
    type: Boolean,
  },
  editFunction: {
    type: Object,
    default: () => ({
      name: '',
      description: '',
      query: '',
      isManaged: false,
    }),
  },
});

// Emits
const emit = defineEmits<{
  'reload:functions': [];
  'update:dialog': [close?: boolean];
}>();

// Methods
const onCreateFunction = async () => {
  // TODO: Dan - remove this try/catch spew, any axios
  // api call should be resolved properly.
  try {
    if (props.editFunction !== null) {
      await functionUpdate(func.uuid, func);
    } else {
      func.uuid = generateUuidv4();
      await functionCreate(func);
    }
    emit('reload:functions');
    closeDialog();

    eventBus.$emit('show:snackbar', {
      message: 'Successfully saved function.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    creationError.value = err.response.data.error;

    eventBus.$emit('show:snackbar', {
      message: err.response.data.error,
      color: 'error',
      icon: 'mdi-alert',
    });
  }
};

const closeDialog = function () {
  emit('update:dialog', false);
};

// Before Mount
onBeforeMount(() => {
  if (props.editFunction !== null) {
    Object.assign(func, props.editFunction);
  }
});

onMounted(() => {
  eventBus.$on('editor:change:functionEditor', (updatedQuery: string) => {
    func.query = updatedQuery;
  });
});
</script>
<style></style>
