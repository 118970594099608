<template>
  <div>
    <div class="ag-status-name-value ag-status-panel">
      <span>Execution Time:</span>
      <span class="pl-1 ag-status-name-value-value">{{ executionTime }}</span>
    </div>
    <div
      v-if="cpuUsage"
      class="ag-status-name-value ag-status-panel"
    >
      <span>CPU Time:</span>
      <span class="pl-1 ag-status-name-value-value">{{ cpuUsage }}</span>
    </div>
    <div
      v-if="memoryUsage"
      class="ag-status-name-value ag-status-panel"
    >
      <span>Memory:</span>
      <span class="pl-1 ag-status-name-value-value">{{ memoryUsage }}MB</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';

// Props
const props = defineProps(['params']);
// Computed
const executionTime = computed(() => {
  return props.params.context.componentParent.executionTime;
});

const cpuUsage = computed(() => {
  return props.params.context.componentParent.cpuUsage;
});

const memoryUsage = computed(() => {
  const memoryUsage = props.params.context.componentParent.memoryUsage;
  if (memoryUsage) {
    return Math.round(memoryUsage / 1024 / 1024);
  }
  return null;
});

watch(
  () => props.params,
  (val, preVal) => {
    if (val !== preVal) {
      console.log(val);
    }
  },
);
</script>
