<template>
  <v-dialog :model-value="dialog" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Query Help</span>
      </v-card-title>
      <v-card-text>
        <div class="text-subtitle-2">Required Fields</div>
        <v-table>
          <template #default>
            <thead>
              <tr>
                <th>Field</th>
                <th>Type</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><code>EventId</code></td>
                <td>Tagging</td>
                <td>
                  Unique identifier for this event e.g. ReportGuid, DetectionId,
                  AlertId
                </td>
              </tr>
              <tr>
                <td><code>EventTime</code></td>
                <td>Tagging,Queries</td>
                <td>
                  Set to an interesting time e.g. ReportTime, FileCreationTime.
                </td>
              </tr>
              <tr>
                <td><code>Cluster</code></td>
                <td>Queries</td>
                <td>
                  Set to the cluster where the data exists. Required for almost
                  all pivots.
                </td>
              </tr>
              <tr>
                <td><code>MachineId</code></td>
                <td>Queries</td>
                <td>
                  Set to the MachineId e.g. PrimaryMachineId or summarize by
                  MachineId. Required for most device pivots.
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
        <div class="text-subtitle-2 mt-5">Time Range Parameters</div>
        <div class="text--primary my-2">
          The <code>StartTime</code> and <code>EndTime</code> time range
          parameters are injected into the Kusto query using
          <code>query_parameters</code>.
        </div>
        <v-card class="my-2 pa-2" border>
          <pre class="code">
declare query_parameters(<strong>StartTime</strong>:datetime, <strong>EndTime</strong>:datetime);
...
| where ReportArrivalTimeUtc between (<strong>StartTime</strong> .. <strong>EndTime</strong>)</pre>
        </v-card>
        <div class="text-subtitle-2 mt-5">Tagged Events</div>
        <div class="text--primary my-2">
          This helper function can be used to highlight events that have been
          tagged by yourself or other analysts. The table must have a column
          named <code>EventId</code>.
        </div>
        <v-card class="my-2 pa-2" border>
          <pre class="code">
let getTagEvents=(T:(EventId:string)) {
  let EventIds=materialize(T | distinct EventId);
  let Events=EventIds
  | join kind=leftouter (
    cluster('{{ defaultMteCluster }}').database('{{
      defaultMteDatabase
    }}').SavedEvent
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, *) by EventId
    | project EventId, IsSaved=true
  ) on EventId
  | join kind=leftouter (
    cluster('{{ defaultMteCluster }}').database('{{
      defaultMteDatabase
    }}').EventTag
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, IsDeleted) by EventId, Tag
    | where not(IsDeleted)
    | summarize Tags=make_set(Tag) by EventId
    | project EventId, Tags
  ) on EventId
  | join kind=leftouter (
    cluster('{{ defaultMteCluster }}').database('{{
      defaultMteDatabase
    }}').EventComment
    | where EventId in (EventIds)
    | sort by DateTimeUtc desc
    | summarize arg_max(DateTimeUtc, Determination, IsDeleted, Comment),
      Comments=make_list(pack("CreatedBy", CreatedBy, "Comment", Comment, "Determination", Determination, "DateTimeUtc", DateTimeUtc))
      by EventId
    | where not(IsDeleted)
    | project EventId, Determination, Comment, Comments
  ) on EventId
  | project-away EventId1, EventId2, EventId3
  | extend TagEvent=pack_all()
  | project EventId, TagEvent;
  T
  | join kind=inner Events on EventId
  | project-away EventId1
};
CreateFileEvents
| take 1
| invoke GetTagEvents()</pre>
        </v-card>
        <div class="text-subtitle-2 mt-5">Examples</div>
        <v-card class="my-2 pa-2" border>
          <pre class="code">{{ defaultNewQuery }}}</pre>
        </v-card>
        <v-card class="my-2 pa-2" border>
          <pre class="code">
declare query_parameters(StartTime:datetime, EndTime:datetime);
MteTrapHits
| where TrapWindowStart between (StartTime .. EndTime)
| take 1
| extend EventTime=TrapWindowStart, Cluster=todynamic(AdditionalMetadata).Cluster, MachineId=PrimaryMachineId</pre>
        </v-card>
        <v-card class="my-2 pa-2" border>
          <pre class="code">
declare query_parameters(StartTime:datetime, EndTime:datetime);
CreateFileEvents
| where ReportArrivalTimeUtc between (StartTime .. EndTime)
| take 1
| extend EventTime=ReportTime, Cluster=current_cluster_endpoint(), EventType="CreateFileEvent"</pre>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-darken-1" variant="text" @click="$emit('update:dialog', false)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { defaultNewQuery } from '@/renderer/displayComponent';
import { VDialog } from 'vuetify/components';
import { computed } from 'vue';

// Props
const props = defineProps({
  dialog: {
    type: Boolean,
    default: false,
  },
});

// Computed
const defaultNewQuery = computed(() => {
  return defaultNewQuery();
});

const defaultMteCluster = computed(() => {
  return process.env.VUE_APP_MTE_KUSTO_CLUSTER;
});

const defaultMteDatabase = computed(() => {
  return process.env.VUE_APP_MTE_KUSTO_DATABASE;
});

//export default {
//  name: "QueryHelperDialog",
//  props: {
//    dialog: {
//      type: Boolean,
//      default: false,
//    },
//  },
//  emits: ["update:dialog"],
//  computed: {
//    defaultNewQuery() {
//      return defaultNewQuery();
//    },
//    defaultMteCluster() {
//      return process.env.VUE_APP_MTE_KUSTO_CLUSTER;
//    },
//    defaultMteDatabase() {
//      return process.env.VUE_APP_MTE_KUSTO_DATABASE;
//    },
//  },
//};
</script>
