<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    :scrim="false"
    :retain-focus="false"
    no-click-animation
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="popup-header">
          <span class="text-h5">Customise Tag IOC</span>
        </v-card-title>
        <v-card-subtitle>
          {{ row_events.length }} event(s) selected
        </v-card-subtitle>
        <v-card-text>
          <v-container
            fluid
            no-gutters
          >
            <v-row v-if="objectType === 'ioc'">
              <v-col class="py-1">
                <v-text-field
                  v-model="title"
                  label="Title"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="titleAction"
                  :items="[actions.Ignore, actions.Override]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="description"
                  :label="objectTypeC"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="descriptionCol"
                  :items="columnNames"
                  @update:model-value="onDescriptionColChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-select
                  v-model="observableType"
                  :items="observableTypeList"
                  :disabled="observableAction === actions.Ignore"
                  :rules="[
                    (v) =>
                      observableAction === actions.Ignore ||
                      !!v ||
                      'Observable type cannot be empty.',
                  ]"
                  label="Observable Type"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="observableAction"
                  :items="[actions.Ignore, actions.Override, actions.Append]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-select
                  v-model="determination"
                  :items="determinationOptions"
                  :disabled="
                    determinationAction === actions.Ignore ||
                    determinationAction === actions.Remove
                  "
                  :rules="[
                    (v) =>
                      determinationAction === actions.Ignore ||
                      determinationAction === actions.Remove ||
                      !!v ||
                      'Determination cannot be empty.',
                  ]"
                  label="Determination"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="determinationAction"
                  :items="[actions.Ignore, actions.Override, actions.Remove]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="comment"
                  label="Comment"
                  :disabled="commentAction === actions.Ignore"
                  :rules="[
                    (v) =>
                      commentAction === actions.Ignore ||
                      !!v ||
                      'Comment cannot be empty.',
                  ]"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="commentAction"
                  :items="[actions.Ignore, actions.Override, actions.Append]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="selectedTags"
                  v-model:search="search"
                  :items="tags"
                  chips
                  closable-chips
                  hide-details
                  hide-selected
                  label="Tags"
                  multiple
                  :disabled="tagAction === actions.Ignore"
                >
                  <template
                    v-if="tagAction !== actions.Remove"
                    #prepend-item
                  >
                    <v-list-item
                      ripple
                      :disabled="!search"
                      @mousedown.prevent
                      @click="onClickTagSelect"
                    >
                      <v-list-item-title>
                        {{ search || 'Type to add new tag...' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Custom tag</v-list-item-subtitle>
                    </v-list-item>
                    <v-divider class="mt-2" />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                class="py-1"
                cols="2"
              >
                <v-select
                  v-model="tagAction"
                  :items="[
                    actions.Ignore,
                    actions.Append,
                    actions.Remove,
                    actions.Override,
                  ]"
                  @update:model-value="onClickChangeTagAction"
                />
              </v-col>
            </v-row>
          </v-container>
          <ul
            v-for="(error, index) in errors"
            :key="`error-${index}`"
            class="text-error"
          >
            <li>{{ error }}</li>
          </ul>
          <ul
            v-for="(modification, index) in modifications"
            :key="`mod-${index}`"
          >
            <li>{{ modification }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="onSaveTagObject()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { eventBus } from '@/main';
import {
  createObjectComments,
  createObjectTags,
  saveIndicators,
  createSavedQuery,
} from '@/services/apiClient';
import {
  Actions,
  presetTags,
  retrieveRecentTags,
  tagsFromData,
  eventDeterminations,
  tagToLabel,
  generateObjectCommentsList,
  generateObjectTagsList,
  generateSavedIndicator,
  generateDeleteObjectList,
  getObjectMeta,
  updateEventTags,
  updateObjectInvestigationRows,
  observableTypes,
  inferObservableType,
  updateInvEventDet,
} from '@/renderer/tags';
import { mustacheExpand } from '@/renderer/kusto_queries';
import { resolveQuery } from '@/renderer/displayComponent';
import { createQueryId } from '@/renderer/utils';
const spectreCols = ['Title', 'ObservableValue', 'ObservableType'];
let _onSuccess = null;

import { ref, computed, watch, onMounted } from 'vue';
import { VForm } from 'vuetify/components';
import { useStore } from '@/store/store';

// Data
const dialog = ref(false);
const determination = ref<string>('');
const determinationOptions = ref(eventDeterminations);
const form = ref<VForm>();
const comment = ref(null);
const search = ref(null);
const recentTags = ref([]);
const selectedTags = ref([]);
const pinnedTags = ref([]);
const commentAction = ref(Actions.Override);
const determinationAction = ref(Actions.Override);
const tagAction = ref(Actions.Ignore);
const row_events = ref([]);
const valid = ref(true);
const errors = ref<string[]>([]);
const modifications = ref<string[]>([]);
const modifiedEvents = ref([]);
const description = ref('');
const descriptionCol = ref('');
const investigationView = ref(false);
const objectType = ref('ioc');
const objectTypeC = ref('IOC');
const columnNames = ref([]);
const observableType = ref('');
const observableTypeList = ref(observableTypes);
const observableAction = ref(Actions.Override);
const title = ref('');
const titleAction = ref(Actions.Override);
const isFromSpectre = ref(false);
const engagementName = ref('');
const _queryUuid = ref('');

// Store
const store = useStore();
// Computed
const ssirpTags = computed(() => {
  return store.getters['engagement/getTopTags'];
});
const tags = computed(() => {
  if (tagAction.value === Actions.Remove) {
    return [...new Set([...selectedTags.value, ...existingTags.value])];
  }
  return [
    ...new Set([
      ...selectedTags.value,
      ...existingTags.value,
      ...recentTags.value,
      ...presetTags().union(new Set(ssirpTags.value)),
    ]),
  ];
});

const existingTags = computed(() => {
  return row_events.value.map((data) => tagsFromData(data)).flat();
});

const existingTagsObj = computed(() => {
  return row_events.value.reduce((obj, data) => {
    tagsFromData(data).forEach((tag) => {
      if (!obj[tag]) {
        obj[tag] = [];
      }
      obj[tag].push(data);
    });
    return obj;
  }, {});
});

const getEngagement = computed(() => {
  return store.getters['enagement/getEngagement'];
});

const actions = computed(() => {
  return Actions;
});

const unsavedEvents = computed(() => {
  return row_events.value.filter((data) => data.TagEvent?.IsSaved !== true);
});

const isTimestampChanged = computed(() => {
  return eventTimeCol !== timeFields?.defaultType;
});

// Methods
const onClickTagSelect = function () {
  selectedTags.value = [...new Set([search.value, ...selectedTags.value])];
  search.value = '';
};

const onClickChangeTagAction = function () {
  updateModifications();
};

const onDescriptionColChange = function () {
  description.value = row_events.value[0][descriptionCol.value];
};

const updateModifications = () => {
  modifications.value = [];
  if (selectedTags.value.length === 0) {
    return;
  }
  switch (tagAction.value) {
    case Actions.Append:
      selectedTags.value.forEach((tag) => {
        const modCount = row_events.value.filter(
          (data) => isTimestampChanged || !tagsFromData(data).includes(tag),
        );
        if (modCount.length > 0) {
          modifications.value.push(
            `Adding ${tag} to ${modCount.length} event(s).`,
          );
        }
      });
      break;
    case Actions.Remove:
      selectedTags.value.forEach((tag) => {
        const modCount = row_events.value.filter((data) =>
          tagsFromData(data).includes(tag),
        );
        if (modCount.length > 0) {
          modifications.value.push(
            `Removing ${tag} from ${modCount.length} event(s).`,
          );
        }
      });
      break;
    case Actions.Append:
    case Actions.Remove:
    case Actions.Override:
      selectedTags.value.forEach((tag) => {
        const modCount = row_events.value.filter(
          (data) => isTimestampChanged || !tagsFromData(data).includes(tag),
        );
        if (modCount.length > 0) {
          modifications.value.push(
            `Adding ${tag} to ${modCount.length} event(s).`,
          );
        }
      });
      // Remove tags not in tag selection
      const removeTagCounts = row_events.value.reduce((obj, data) => {
        tagsFromData(data)
          .filter(
            (tag) => !isTimestampChanged && !selectedTags.value.includes(tag),
          )
          .forEach((tag) => {
            if (!(tag in obj)) {
              obj[tag] = 0;
            }
            obj[tag]++;
          });
        return obj;
      }, {});
      Object.entries(removeTagCounts).forEach(([tag, count]) => {
        if (count > 0) {
          modifications.value.push(`Removing ${tag} from ${count} event(s).`);
        }
      });
      break;

    default:
  }
};

const validate = function () {
  errors.value = [];

  if (
    commentAction.value === Actions.Ignore &&
    determinationAction.value === Actions.Ignore &&
    tagAction.value === Actions.Ignore
  ) {
    errors.value.push('At least one action should be selected.');
  }

  if (
    determinationAction.value === Actions.Ignore &&
    unsavedEvents.value.length > 0
  ) {
    errors.value.push(
      `Determination is missing from ${unsavedEvents.value.length} event(s) and cannot be ignored.`,
    );
  }

  if (tagAction.value !== Actions.Ignore && selectedTags.value.length === 0) {
    errors.value.push('Tags cannot be empty.');
  }

  if (
    determinationAction.value === Actions.Remove &&
    (commentAction.value !== Actions.Ignore ||
      tagAction.value !== Actions.Ignore)
  ) {
    errors.value.push(
      'Comment and tag actions must be ignored when removing determination.',
    );
  }

  if (commentAction.value !== Actions.Ignore) {
    try {
      mustacheExpand(comment.value, row_events.value[0]);
    } catch (error) {
      errors.value.push(error);
    }
  }

  return errors.value.length === 0;
};

const removeDetermination = async function () {
  try {
    eventBus.$emit('show:snackbar', {
      message: 'Removing indicators...',
    });
    const engagement = engagementName.value;
    let _events = row_events.value;
    const objectIds = [];
    _events.forEach((event) => {
      const objMeta = getObjectMeta(event, 'ioc', descriptionCol.value);
      objMeta.Determination = 'remove';
      if (investigationView.value) {
        updateInvEventDet(event, 'remove');
      }
      const objId = objMeta?.AdditionalProps?.ObjectId || '';
      if (objId) {
        objectIds.push(objId);
      }
    });

    //if (!isMultiApplicable()) {
    //  events = events.slice(0, 1);
    //}
    const comments = generateDeleteObjectList(objectIds);
    await createObjectComments(comments, engagement);

    //if (investigationView.value) {
    //  const newEvents = updateObjectInvestigationRows(objectType.value, events, objs, comments, tags);
    //  events = newEvents;
    //}

    _onSuccess(row_events.value);

    eventBus.$emit('show:snackbar', {
      message: 'Tag events successfully removed.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    eventBus.$emit('show:snackbar', {
      message: `Removing tag events failed: ${err.toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }

  dialog.value = false;
};

const saveIndicator = async function () {
  if (!row_events.value || row_events.value.length < 1) {
    return [];
  }
  // Re-use object id if editing an existing IOC
  const engagement = engagementName.value;
  const pattern = description.value;
  const metaV = {};
  const newIndicators = [];
  if (!isMultiApplicable()) {
    const event0 = row_events.value[0];
    const objMeta = getObjectMeta(event0, 'ioc', descriptionCol.value);
    const objId = objMeta?.AdditionalProps?.ObjectId || '';
    const newInd = generateSavedIndicator(
      objId,
      event0,
      engagement,
      title.value,
      pattern,
      observableType.value,
      metaV,
    );
    newIndicators.push(newInd);
  } else {
    row_events.value.forEach((event) => {
      const objMeta = getObjectMeta(event, 'ioc', descriptionCol.value);
      const objId = objMeta?.AdditionalProps?.ObjectId || '';
      let newInd = null;
      if (isFromSpectre.value) {
        // For Spectre we use the predefined columns from the query
        newInd = generateSavedIndicator(
          objId,
          event,
          engagement,
          event?.Title,
          event?.ObservableValue,
          event?.ObservableType,
          metaV,
        );
      } else {
        // Use the values from tag dialog to generate indicators
        newInd = generateSavedIndicator(
          objId,
          event,
          engagement,
          title.value,
          event[descriptionCol.value],
          observableType.value,
          metaV,
        );
      }
      newIndicators.push(newInd);
    });
  }
  if (newIndicators && newIndicators.length > 0) {
    const q = await resolveQuery(_queryUuid.value, true);
    // QueryId is a md5 hash of the query, so that same query will have same id
    const queryId = createQueryId(q);
    await saveIndicators(newIndicators, engagement, queryId);
    // Save query used to create these events
    await createSavedQuery(
      q.query,
      q.cluster,
      q.database,
      [],
      _queryUuid.value,
      [],
      queryId,
      false,
    );
  }
  return newIndicators;
};

const saveComments = async function (objectIds) {
  const engagement = engagementName.value;
  let _events = row_events.value;
  if (!isMultiApplicable()) {
    _events = row_events.value.slice(0, 1);
  }
  const additionalInfo = {};
  const comments = generateObjectCommentsList(
    _events,
    engagement,
    descriptionCol.value,
    comment.value,
    determination.value,
    objectType.value,
    commentAction.value,
    determinationAction.value,
    additionalInfo,
    objectIds,
  );
  await createObjectComments(comments, engagement);
  return comments;
};

const addTags = async function (objectIds) {
  let _events = row_events.value;
  if (!isMultiApplicable()) {
    _events = row_events.value.slice(0, 1);
  }
  const addTagList = generateObjectTagsList(
    _events,
    engagementName.value,
    selectedTags.value,
    descriptionCol.value,
    false,
    objectIds,
  );
  await createObjectTags(addTagList, engagementName.value);
  return addTagList;
};

const removeTags = async function (objectIds) {
  let _events = row_events.value;
  if (!isMultiApplicable()) {
    _events = row_events.value.slice(0, 1);
  }
  const removeTagList = generateObjectTagsList(
    _events,
    engagementName.value,
    selectedTags.value,
    descriptionCol.value,
    true,
    objectIds,
  );
  await createObjectTags(removeTagList, engagementName.value);
  // TODO - get delta
  return [];
};

const isMultiApplicable = function () {
  if (row_events.value.length <= 1) {
    return false;
  }
  if (row_events.value[0][descriptionCol.value] === description.value) {
    // If IOC value has not been edited from the description column value,
    // we allow changes to all selected columns
    return true;
  }
  // If importing from Spectre
  if (!isFromSpectre.value) {
    return false;
  }
  if (title.value !== row_events.value[0]['Title']) {
    return false;
  }
  if (description.value !== row_events.value[0]['ObservableValue']) {
    return false;
  }
  if (observableType.value !== row_events.value[0]['ObservableType']) {
    return false;
  }
  return true;
};

const onSaveTagObject = async function () {
  const formValid = await form.value?.validate().then((form) => {
    return form.valid;
  });

  if (!formValid || !validate()) {
    return false;
  }

  if (determinationAction.value === Actions.Remove) {
    return await removeDetermination();
  }

  eventBus.$emit('show:snackbar', {
    message: 'Customising tag IOC...',
  });

  try {
    let objs = [];
    let comments = [];
    let tags = [];
    let objectIds = [];
    if (determinationAction.value === Actions.Override) {
      objs = await saveIndicator();
      if (objs && objs.length > 0) {
        objs.forEach((obj) => {
          objectIds.push(obj.objectId);
        });
      }
    }

    if (
      determinationAction.value !== Actions.Ignore ||
      commentAction.value !== Actions.Ignore
    ) {
      comments = await saveComments(objectIds);
    }

    if (
      tagAction.value === Actions.Append ||
      tagAction.value === Actions.Override
    ) {
      // TODO: implement set tags
      tags = await addTags(objectIds);
    } else if (tagAction.value === Actions.Remove) {
      tags = await removeTags(objectIds);
    }
    //const newEvents = this.updateEventTags(events, objs, comments, tags);
    let newEvents = [];
    if (investigationView.value) {
      newEvents = updateObjectInvestigationRows(
        objectType.value,
        row_events.value,
        objs,
        comments,
        tags,
      );
    } else {
      newEvents = updateEventTags(
        row_events.value,
        objs,
        comments,
        tags,
        objectType.value,
        descriptionCol.value,
      );
    }

    _onSuccess(newEvents);

    determination.value = null;
    comment.value = null;
    selectedTags.value = [];

    eventBus.$emit('show:snackbar', {
      message: 'Tag IOC successfully customised.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    eventBus.$emit('show:snackbar', {
      message: `Customisation of tag IOC failed: ${err.toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }

  dialog.value = false;
};

// Watch
watch(selectedTags, function () {
  updateModifications();
});

// Lifecycle
onMounted(() => {
  eventBus.$on(
    'create:tag-indicator-dialog',
    async ({
      events,
      onSuccess,
      objectType,
      colName,
      tags,
      investigationView,
      engagementName : _engagementName,
      queryUuid,
    }) => {
      dialog.value = true;
      row_events.value = events;
      _onSuccess = onSuccess;
      investigationView = investigationView;
      descriptionCol.value = colName;
      _engagementName = _engagementName || getEngagement.value;
      engagementName.value = _engagementName;  // Save value for use by other functions
      _queryUuid.value = queryUuid;
      // TODO: check events is not empty
      const event0 = events[0];
      columnNames.value = Object.keys(event0);
      const isSpectre = spectreCols.every((val) =>
        columnNames.value.includes(val),
      );
      isFromSpectre.value = isSpectre;
      if (isSpectre) {
        title.value = event0?.Title;
        description.value = event0?.ObservableValue;
        observableType.value = event0?.ObservableType;
        determination.value = 'Malicious';
        comment.value = event0?.Title;
      } else if (investigationView) {
        title.value = event0?.Title || event0?.IOC || event0[colName];
        description.value = event0?.IOC || event0[colName];
        if (columnNames.value.includes('IOC')) {
          descriptionCol.value = 'IOC';
        }
        comment.value = event0?.Comment;
        observableType.value = event0?.ObservableType;
        determination.value = tagToLabel(event0?.Determination);
      } else {
        description.value = event0[colName] ?? '';
        title.value = description.value;
      }
      if (_engagementName) {
        selectedTags.value = tags;
        tagAction.value = Actions.Append;
      } else {
        selectedTags.value = [];
        tagAction.value = Actions.Ignore;
      }
      //const objMeta = getObjectMeta(event0, description, objectType);
      const objMeta = getObjectMeta(event0, objectType, colName);
      if (objMeta) {
        determination.value = tagToLabel(objMeta?.Determination || '');
        comment.value = objMeta?.Comment;
        observableType.value = objMeta?.AdditionalProps?.ObservableType;
        title.value = objMeta?.AdditionalProps?.Description;
        description.value = objMeta?.Value || description.value;
      }
      if (!observableType) {
        observableType.value = inferObservableType(
          descriptionCol,
          event0[descriptionCol.value],
        );
      }
      errors.value = [];
      modifications.value = [];
      recentTags.value = await retrieveRecentTags();
    },
  );
});
</script>
