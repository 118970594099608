<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    :scrim="false"
    :retain-focus="false"
    no-click-animation
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="popup-header">
          <span class="text-h5">Customise Tag Events</span>
        </v-card-title>
        <v-card-subtitle>
          {{ row_events.length }} event(s) selected
        </v-card-subtitle>
        <v-card-text>
          <v-container
            fluid
            no-gutters
          >
            <v-row>
              <v-col class="py-1">
                <v-select
                  v-model="determination"
                  :items="eventDeterminations"
                  :disabled="
                    determinationAction === actions.Ignore ||
                    determinationAction === actions.Remove
                  "
                  :rules="[
                    (v) =>
                      determinationAction === actions.Ignore ||
                      determinationAction === actions.Remove ||
                      !!v ||
                      'Determination cannot be empty.',
                  ]"
                  label="Determination"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="determinationAction"
                  :items="[actions.Ignore, actions.Override, actions.Remove]"
                  @update:model-value="onDeterminationActionChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="eventTime"
                  label="Event Time"
                  :disabled="
                    !manualEntryView &&
                    (!row_events ||
                      !eventTimeCol ||
                      eventTimeCol in row_events[0] ||
                      (investigationView && row_events.length > 1))
                  "
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-combobox
                  v-model="eventTimeCol"
                  :items="
                    Object.keys(eventTimeFields?.times || {}).filter(
                      (x) => x !== 'TimestampType',
                    )
                  "
                  :disabled="investigationView && !manualEntryView"
                  @update:model-value="onTimestampTypeChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="deviceIp"
                  label="Device IP Address"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="deviceIpCol"
                  :items="deviceIpFields"
                  @update:model-value="onDeviceIpChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="destEntity"
                  label="Destination Entity"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="destEntityCol"
                  :items="destEntityFields"
                  @update:model-value="onDestEntityChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="sourceEntity"
                  label="Source Entity"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="sourceEntityCol"
                  :items="sourceEntityFields"
                  @update:model-value="onSourceEntityChange"
                />
              </v-col>
            </v-row>
            <v-row v-if="manualEntryView">
              <v-col class="py-1">
                <v-textarea
                  v-model="row_events[0].RawActivity"
                  label="Raw Activity"
                  rows="3"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="comment"
                  label="Investigation Notes"
                  :disabled="commentAction === actions.Ignore"
                  :rules="[
                    (v) =>
                      commentAction === actions.Ignore ||
                      !!v ||
                      'Comment cannot be empty.',
                  ]"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="commentAction"
                  :items="[actions.Ignore, actions.Override, actions.Append]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="selectedTags"
                  v-model:search="search"
                  :items="tags"
                  chips
                  closable-chips
                  density="compact"
                  hide-details
                  hide-selected
                  label="Tags"
                  multiple
                  :disabled="tagAction === actions.Ignore"
                >
                  <template
                    v-if="tagAction !== actions.Remove"
                    #prepend-item
                  >
                    <v-list-item
                      ripple
                      :disabled="!search"
                      @mousedown.prevent
                      @click="onClickTagSelect"
                    >
                      <v-list-item-title>
                        {{ search || 'Type to add new tag...' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Custom tag</v-list-item-subtitle>
                    </v-list-item>
                    <v-divider class="mt-2" />
                  </template>
                  <!--
                  <template #item="{ item }">
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item in existingTagsObj">
                      Exists in {{ existingTagsObj[item].length }} event(s)
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="recentTags.includes(item)">
                      Recent tag
                    </v-list-item-subtitle>
                  </template>
                  -->
                </v-autocomplete>
              </v-col>
              <v-col
                class="py-1"
                cols="2"
              >
                <v-select
                  v-model="tagAction"
                  :items="[
                    actions.Ignore,
                    actions.Append,
                    actions.Remove,
                    actions.Override,
                  ]"
                  @update:model-value="onClickChangeTagAction"
                />
              </v-col>
            </v-row>
          </v-container>
          <ul
            v-for="(error, index) in errors"
            :key="`error-${index}`"
            class="text-error"
          >
            <li>{{ error }}</li>
          </ul>
          <ul
            v-for="(modification, index) in modifications"
            :key="`mod-${index}`"
          >
            <li>{{ modification }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="onSaveTagEvent()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<!--
<template>
  <v-dialog
    v-model="dialog"
    max-width="60%"
    persistent
    :retain-focus="false"
    no-click-animation
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-stepper
        alt-labels
        editable
        :items="['Required', 'Optional', 'Tags', 'Assets']"
      >
        <template v-slot:item.1>
          <v-card
            title="Customise Tag Events"
            flat
          >
            <v-card-subtitle>
              {{ row_events.length }} event(s) selected
            </v-card-subtitle>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="determination"
                    :items="eventDeterminations"
                    label="Determination"
                    :rules="[(v) => !!v || 'Determination cannot be empty.']"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="eventTime"
                    label="Event Time"
                    :disabled="
                      !manualEntryView &&
                      (!row_events ||
                        !eventTimeCol ||
                        eventTimeCol in row_events[0] ||
                        (investigationView && row_events.length > 1))
                    "
                  />
                </v-col>
                <v-col cols="4">
                  <v-combobox
                    v-model="eventTimeCol"
                    :items="
                      Object.keys(eventTimeFields?.times || {}).filter(
                        (x) => x !== 'TimestampType',
                      )
                    "
                    :disabled="investigationView && !manualEntryView"
                    @update:model-value="onTimestampTypeChange"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="comment"
                    label="Investigation Notes"
                    :rules="[
                      (v) =>
                        commentAction === actions.Ignore ||
                        !!v ||
                        'Comment cannot be empty.',
                    ]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>

        <template v-slot:item.2>
          <v-card
            title="Optional Fields"
            flat
          >
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="deviceIp"
                    label="Device IP Address"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="deviceIpCol"
                    :items="deviceIpFields"
                    @update:model-value="onDeviceIpChange"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="destEntity"
                    label="Destination Entity"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="destEntityCol"
                    :items="destEntityFields"
                    @update:model-value="onDestEntityChange"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="sourceEntity"
                    label="Source Entity"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="sourceEntityCol"
                    :items="sourceEntityFields"
                    @update:model-value="onSourceEntityChange"
                  />
                </v-col>
              </v-row>
              <v-row v-if="manualEntryView">
                <v-col>
                  <v-textarea
                    v-model="row_events[0].RawActivity"
                    label="Raw Activity"
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>

        <template v-slot:item.3>
          <v-card
            title="Tags"
            flat
          >
            <v-container>
              <v-autocomplete
                v-model="selectedTags"
                v-model:search-input="search"
                :items="tags"
                chips
                closable-chips
                hide-details
                hide-selected
                label="Tags"
                multiple
              >
                <template #prepend-item>
                  <v-list-item
                    ripple
                    :disabled="!search"
                    @mousedown.prevent
                    @click="onClickTagSelect"
                  >
                    <v-list-item-title>
                      {{ search || 'Type to add new tag...' }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Custom tag</v-list-item-subtitle>
                  </v-list-item>
                  <v-divider class="mt-2" />
                </template>
              </v-autocomplete>
            </v-container>
          </v-card>
        </template>

        <template v-slot:item.4>
          <v-card
            title="Assets"
            flat
            >...</v-card
          >
        </template>
      </v-stepper>
    </v-form>
  </v-dialog>
</template>
-->
<script setup lang="ts">
import { eventBus } from '@/main';
import { Auth as auth } from '@/services/auth';
import {
  createComments,
  createTags,
  saveEvents as apiSaveEvents,
  createSavedQuery,
} from '@/services/apiClient';
import {
  Actions,
  getComment,
  presetTags,
  sourceTags,
  retrieveRecentTags,
  tagsDiff,
  tagsFromData,
  tagsIntersect,
  tagToLabel,
  eventDeterminations,
  inferIpColumns,
} from '@/renderer/tags';
import { mustacheExpand } from '@/renderer/kusto_queries';
import { extractEventId, createQueryId } from '@/renderer/utils';
import { resolveQuery } from '@/renderer/displayComponent';

import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from '@/store/store';
import { VForm } from 'vuetify/components';
let _onSuccess = null;
// Data
const dialog = ref(false);
const determination = ref(null);
const form = ref<VForm>();
const comment = ref(null);
const search = ref(null);
const recentTags = ref([]);
const selectedTags = ref([]);
const pinnedTags = ref([]);
const commentAction = ref(Actions.Override);
const determinationAction = ref(Actions.Override);
const tagAction = ref(Actions.Ignore);
const row_events = ref([]);
const valid = ref(true);
const errors = ref([]);
const modifications = ref([]);
const modifiedEvents = ref([]);
const eventTime = ref('');
const eventTimeCol = ref('');
const eventTimeFields = ref({});
const investigationView = ref(false);
const manualEntryView = ref(false);
const deviceIp = ref('');
const deviceIpCol = ref('');
const deviceIpFields = ref([]);
const destEntity = ref('');
const destEntityCol = ref('');
const destEntityFields = ref([]);
const sourceEntity = ref('');
const sourceEntityCol = ref('');
const sourceEntityFields = ref([]);
const RawActivity = ref('');
const engagementName = ref('');
const queryUuid = ref('');

// Store
const store = useStore();
// Computed
const getEngagementName = computed(() => {
  return store.getters['engagement/getEngagement'];
});

const ssirpTags = computed(() => {
  return store.getters['engagement/getTopTags'];
});
const tags = computed(() => {
  if (tagAction.value === Actions.Remove) {
    return [...new Set([...selectedTags.value, ...existingTags.value])];
  }
  return [
    ...new Set([
      ...presetTags().union(new Set(ssirpTags.value)),
      ...selectedTags.value,
      ...existingTags.value,
      ...recentTags.value,
      ...sourceTags(),
    ]),
  ];
});

const existingTags = computed(() => {
  return row_events.value.map((data) => tagsFromData(data)).flat();
});

const existingTagsObj = computed(() => {
  return row_events.value.reduce((obj, data) => {
    tagsFromData(data).forEach((tag) => {
      if (!obj[tag]) {
        obj[tag] = [];
      }
      obj[tag].push(data);
    });
    return obj;
  }, {});
});

const actions = computed(() => {
  return Actions;
});

const unsavedEvents = computed(() => {
  return row_events.value.filter((data) => data.TagEvent?.IsSaved !== true);
});

const isTimestampChanged = computed(() => {
  return (
    manualEntryView.value ||
    (!investigationView.value &&
      eventTimeCol.value !== eventTimeFields.value?.defaultType)
  );
});

// Methods
const onClickTagSelect = function () {
  selectedTags.value = [...new Set([search.value, ...selectedTags.value])];
  search.value = '';
};

const onClickChangeTagAction = function () {
  updateModifications();
};

const onTimestampTypeChange = function () {
  const t = eventTimeFields.value.times[eventTimeCol.value];
  if (t) {
    eventTime.value = t;
  }
};

const onDeterminationActionChange = function () {
  if (determinationAction.value === 'Remove') {
    tagAction.value = 'Ignore';
    commentAction.value = 'Ignore';
  }
};

const onDeviceIpChange = function () {
  deviceIp.value = row_events.value[0][deviceIpCol.value];
};

const onSourceEntityChange = function () {
  sourceEntity.value = row_events.value[0][sourceEntityCol.value];
};

const onDestEntityChange = function () {
  destEntity.value = row_events.value[0][destEntityCol.value];
};

const updateModifications = function () {
  modifications.value = [];
  if (selectedTags.value.length === 0) {
    return;
  }

  if (tagAction.value === Actions.Append) {
    selectedTags.value.forEach((tag) => {
      const modCount = row_events.value.filter(
        (data) => isTimestampChanged.value || !tagsFromData(data).includes(tag),
      );
      if (modCount.length > 0) {
        modifications.value.push(
          `Adding ${tag} to ${modCount.length} event(s).`,
        );
      }
    });
  } else if (tagAction.value === Actions.Remove) {
    selectedTags.value.forEach((tag) => {
      const modCount = row_events.value.filter((data) =>
        tagsFromData(data).includes(tag),
      );
      if (modCount.length > 0) {
        modifications.value.push(
          `Removing ${tag} from ${modCount.length} event(s).`,
        );
      }
    });
  } else if (tagAction.value === Actions.Override) {
    selectedTags.value.forEach((tag) => {
      const modCount = row_events.value.filter(
        (data) => isTimestampChanged.value || !tagsFromData(data).includes(tag),
      );
      if (modCount.length > 0) {
        modifications.value.push(
          `Adding ${tag} to ${modCount.length} event(s).`,
        );
      }
    });
    // Remove tags not in tag selection
    const removeTagCounts = row_events.value.reduce((obj, data) => {
      tagsFromData(data)
        .filter(
          (tag) =>
            !isTimestampChanged.value && !selectedTags.value.includes(tag),
        )
        .forEach((tag) => {
          if (!(tag in obj)) {
            obj[tag] = 0;
          }
          obj[tag]++;
        });
      return obj;
    }, {});
    Object.entries(removeTagCounts).forEach(([tag, count]) => {
      if (count > 0) {
        modifications.value.push(`Removing ${tag} from ${count} event(s).`);
      }
    });
  }
};

const validate = function () {
  errors.value = [];

  if (
    commentAction.value === Actions.Ignore &&
    determinationAction.value === Actions.Ignore &&
    tagAction.value === Actions.Ignore
  ) {
    errors.value.push('At least one action should be selected.');
  }

  if (
    determinationAction.value === Actions.Ignore &&
    unsavedEvents.length > 0
  ) {
    errors.value.push(
      `Determination is missing from ${unsavedEvents.length} event(s) and cannot be ignored.`,
    );
  }

  if (tagAction.value !== Actions.Ignore && selectedTags.value.length === 0) {
    errors.value.push('Tags cannot be empty.');
  }

  if (
    determinationAction.value === Actions.Remove &&
    (commentAction.value !== Actions.Ignore || tagAction !== Actions.Ignore)
  ) {
    errors.value.push(
      'Comment and tag actions must be ignored when removing determination.',
    );
  }

  // Check timestamp
  if (
    !/(16|19|20)\d\d-\d\d-\d\d((T| )\d\d:\d\d:\d\d(.\d\d\d+)?)?Z?/.test(
      eventTime.value,
    )
  ) {
    errors.value.push(`Invalid timestamp: ${eventTime.value}`);
  }

  if (commentAction.value !== Actions.Ignore) {
    try {
      mustacheExpand(comment.value, row_events.value[0]);
    } catch (error) {
      errors.value.push(error);
    }
  }

  return errors.value.length === 0;
};

const getDetermination = function (oldDetermination) {
  switch (determinationAction.value) {
    case Actions.Ignore:
      return oldDetermination;
    case Actions.Override:
      return determination.value;
  }
};

const removeDetermination = async function () {
  try {
    eventBus.$emit('show:snackbar', {
      message: 'Removing tag events...',
    });

    let comments = [];
    const newEvents = row_events.value.map((data) => {
      comments.push({
        eventId: extractEventId(data._id),
        timestampType: eventTimeCol.value,
        comment: 'removed',
        determination: 'removed',
        isDeleted: true,
      });
      return {
        ...data,
        TagEvent: null,
      };
    });
    await createComments(comments, engagementName.value);
    row_events.value = newEvents;
    _onSuccess(row_events.value);

    eventBus.$emit('show:snackbar', {
      message: 'Tag events successfully removed.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    eventBus.$emit('show:snackbar', {
      message: `Removing tag events failed: ${err.toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }

  dialog.value = false;
};

const saveEvents = async function () {
  let events = [];
  const newEvents = row_events.value.map((data) => {
    if (manualEntryView.value) {
      data.EventTime = eventTime.value;
    }
    const wdata = {
      ...data,
    };
    delete wdata.TagEvent;
    if (data.TagEvent?.IsSaved !== true) {
      events.push({
        eventId: extractEventId(data._id),
        eventTime: data?.EventTime || eventTime.value,
        data: wdata,
      });
    }

    return {
      ...data,
      TagEvent: {
        ...data.TagEvent,
        IsSaved: true,
      },
    };
  });

  if (events.length > 0) {
    if (!investigationView.value || manualEntryView.value) {
      // For investigation view, don't overwrite saved events
      const q = await resolveQuery(queryUuid.value, true);
      // QueryId is a md5 hash of the query, so that same query will have same id
      const queryId = createQueryId(q);
      await apiSaveEvents(events, engagementName.value, queryId);
      // Save query used to create these events
      await createSavedQuery(
        q.query,
        q.cluster,
        q.database,
        [],
        queryUuid.value,
        [],
        queryId,
        false,
      );
    }
    row_events.value = newEvents;
  }
};

const saveComments = async function () {
  const user_id = await auth.getAccount();
  let comments = [];
  const event0 = row_events.value[0];
  const nevents = row_events.value.length;
  const newEvents = row_events.value.map((data) => {
    let _comment = getComment(
      comment.value,
      data.TagEvent?.Comment || '',
      commentAction.value,
    );
    try {
      _comment = mustacheExpand(comment.value, data);
    } catch (error) {
      // Should have already been checked in validation
    }
    const determination = getDetermination(
      data.TagEvent?.Determination,
    ).toLowerCase();
    const addInfo = {
      deviceIpAddress:
        deviceIp.value === event0[deviceIpCol.value]
          ? data[deviceIpCol.value]
          : deviceIp.value,
      destinationEntity:
        destEntity.value === event0[destEntityCol.value]
          ? data[destEntityCol.value]
          : destEntity.value,
      sourceEntity:
        sourceEntity.value === event0[sourceEntityCol.value]
          ? data[sourceEntityCol.value]
          : sourceEntity.value,
    };
    // TODO: how to handle changing timestamp type
    const timestampType = investigationView.value
      ? data?.TimestampType || eventTimeCol.value
      : eventTimeCol.value;
    let _eventTime = data[eventTimeCol.value]
      ? data[eventTimeCol.value]
      : eventTime.value;
    if (investigationView.value) {
      _eventTime =
        nevents == 1 ? eventTime.value : data?.EventTime || eventTime.value;
    }
    comments.push({
      eventId: extractEventId(data._id),
      timestampType,
      eventTime: _eventTime,
      comment: _comment,
      determination: determination,
      additionalInfo: addInfo,
      isDeleted: false,
    });

    if (investigationView.value) {
      data['InvestigationNotes'] = comment.value;
      data['DeviceIpAddress'] = addInfo?.deviceIpAddress;
      data['DestinationEntity'] = addInfo?.destinationEntity;
      data['SourceEntity'] = addInfo?.sourceEntity;
      data['Determination'] = determination;
    }
    if (manualEntryView.value) {
      data['TimestampType'] = eventTimeCol.value;
      data['Timestamp'] = eventTime.value;
      data['EventTime'] = eventTime.value;
      data['DateAdded'] = new Date().toISOString();
      data['AddedBy'] = user_id?.username;
      data['TLP'] = 'Cx';
    }

    return {
      ...data,
      TagEvent: {
        ...data.TagEvent,
        Comment: _comment,
        Determination: determination,
        AdditionalInfo: addInfo,
      },
    };
  });
  await createComments(comments, engagementName.value);
  row_events.value = newEvents;
};

const addTags = async function () {
  let addTagList = [];
  const newEvents = row_events.value.map((data) => {
    const addTags = isTimestampChanged.value
      ? selectedTags.value
      : tagsDiff(selectedTags.value, tagsFromData(data));
    const timestampType =
      investigationView.value && !manualEntryView.value
        ? data?.TimestampType || eventTimeCol.value
        : eventTimeCol.value;

    addTagList.push(
      ...addTags.map((tag) => ({
        eventId: extractEventId(data._id),
        timestampType,
        tag,
        isDeleted: false,
      })),
    );

    const tags = [...tagsFromData(data), ...addTags];
    const e = {
      ...data,
      TagEvent: {
        ...data.TagEvent,
        Tags: tags,
      },
    };
    if (investigationView.value) {
      e['Tags'] = JSON.stringify(tags);
    }
    return e;
  });
  await createTags(addTagList, engagementName.value);
  row_events.value = newEvents;
};

const removeTags = async function () {
  let removeTagList = [];

  const newEvents = row_events.value.map((data) => {
    const removeTags = tagsIntersect(tagsFromData(data), selectedTags.value);
    const timestampType =
      investigationView.value && !manualEntryView.value
        ? data?.TimestampType || eventTimeCol.value
        : eventTimeCol.value;

    removeTagList.push(
      ...removeTags.map((tag) => ({
        eventId: extractEventId(data._id),
        timestampType,
        tag,
        isDeleted: true,
      })),
    );

    const tags = tagsDiff(tagsFromData(data), selectedTags.value);
    const e = {
      ...data,
      TagEvent: {
        ...data.TagEvent,
        Tags: tags,
      },
    };
    if (investigationView.value) {
      e['Tags'] = JSON.stringify(tags);
    }
    return e;
  });
  await createTags(removeTagList, engagementName.value);
  row_events.value = newEvents;
};

const setTags = async function () {
  let modifyTags = [];
  const newEvents = row_events.value.map((data) => {
    const addTags = isTimestampChanged.value
      ? selectedTags.value
      : tagsDiff(selectedTags.value, tagsFromData(data));
    const removeTags = isTimestampChanged.value
      ? []
      : tagsDiff(tagsFromData(data), selectedTags.value);
    const timestampType =
      investigationView.value && !manualEntryView.value
        ? data?.TimestampType || eventTimeCol.value
        : eventTimeCol.value;

    modifyTags.push(
      ...addTags.map((tag) => ({
        eventId: extractEventId(data._id),
        timestampType,
        tag,
        isDeleted: false,
      })),
    );
    modifyTags.push(
      ...removeTags.map((tag) => ({
        eventId: extractEventId(data._id),
        timestampType,
        tag,
        isDeleted: true,
      })),
    );

    const e = {
      ...data,
      TagEvent: {
        ...data.TagEvent,
        Tags: selectedTags.value,
      },
    };
    if (investigationView.value) {
      e['Tags'] = JSON.stringify(selectedTags.value);
    }
    return e;
  });
  await createTags(modifyTags, engagementName.value);
  row_events.value = newEvents;
};

const onSaveTagEvent = async function () {
  const formValid = await form.value?.validate().then((form) => {
    return form.valid;
  });

  if (!formValid || !validate()) {
    return false;
  }

  if (determinationAction.value === Actions.Remove) {
    return await removeDetermination();
  }

  eventBus.$emit('show:snackbar', {
    message: 'Customising tag events...',
  });

  try {
    if (determinationAction.value === Actions.Override) {
      await saveEvents();
    }

    if (
      determinationAction.value !== Actions.Ignore ||
      commentAction.value !== Actions.Ignore
    ) {
      await saveComments();
    }

    if (tagAction.value === Actions.Append) {
      await addTags();
    } else if (tagAction.value === Actions.Remove) {
      await removeTags();
    } else if (tagAction.value === Actions.Override) {
      await setTags();
    }

    _onSuccess(row_events.value);

    determination.value = null;
    comment.value = null;
    selectedTags.value = [];

    eventBus.$emit('show:snackbar', {
      message: 'Tag events successfully customised.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    eventBus.$emit('show:snackbar', {
      message: `Customisation of tag events failed: ${err.toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }

  dialog.value = false;
};

// Mounted
onMounted(() => {
  eventBus.$on(
    'create:tag-event-dialog',
    async ({
      events,
      onSuccess,
      tags,
      timeFields,
      investigationView : _investigationView,
      manualEntryView : _manualEntryView,
      engagementName : _engagementName,
      queryUuid : _queryUuid,
    }) => {
      // Re-initialise variables
      deviceIp.value = '';
      deviceIpCol.value = '';
      deviceIpFields.value = [];
      destEntity.value = '';
      destEntityCol.value = '';
      destEntityFields.value = [];
      sourceEntity.value = '';
      sourceEntityCol.value = '';
      sourceEntityFields.value = [];
      RawActivity.value = '';
      //
      row_events.value = events;
      _onSuccess = onSuccess;
      investigationView.value = _investigationView;
      manualEntryView.value = _manualEntryView;
      _engagementName = _engagementName || getEngagementName.value;
      engagementName.value = _engagementName;
      queryUuid.value = _queryUuid;
      if (row_events.value.length < 1) {
        return;
      }
      const event0 = row_events.value[0];
      const cols = Object.keys(event0);
      if (timeFields && !investigationView.value) {
        eventTimeFields.value = timeFields;
        eventTimeCol.value = eventTimeFields.value?.defaultType;
        eventTime.value = eventTimeCol.value
          ? eventTimeFields.value?.times[eventTimeCol.value] || event0?.EventTime
          : '';
      } else {
        eventTimeFields.value = {};
      }
      if (_engagementName) {
        const ts = event0?.TagEvent?.Tags || [];
        selectedTags.value = [...new Set([...tags, ...ts])];
        tagAction.value = Actions.Append;
      } else {
        selectedTags.value = [];
        tagAction.value = Actions.Ignore;
      }
      if (row_events.value.length >= 1) {
        determination.value = tagToLabel(event0?.TagEvent?.Determination) || '';
        comment.value = event0?.TagEvent?.Comment || '';
        if (investigationView.value) {
          eventTimeCol.value = event0?.TimestampType;
          eventTime.value = event0?.Timestamp;
        }
      }
      if (!comment.value && cols.includes('Note')) {
        comment.value = '{{Note}}';
      }
      errors.value = [];
      modifications.value = [];
      if (determinationAction.value === 'Remove') {
        tagAction.value = 'Ignore';
        commentAction.value = 'Ignore';
      }
      // Set device ip
      deviceIpFields.value = inferIpColumns(cols, event0);
      if (event0?.TagEvent?.AdditionalInfo?.deviceIpAddress) {
        deviceIp.value = event0?.TagEvent?.AdditionalInfo?.deviceIpAddress;
      } else if (deviceIpFields.value.length > 0) {
        deviceIpCol.value = deviceIpFields[0];
        deviceIp.value = event0[deviceIpCol.value];
      }
      // Set dest entity
      destEntityFields.value = cols;
      if (event0?.TagEvent?.AdditionalInfo?.destinationEntity) {
        destEntity.value = event0?.TagEvent?.AdditionalInfo?.destinationEntity;
      } else if (cols.includes('DeviceDnsName')) {
        destEntityCol.value = 'DeviceDnsName';
        destEntity.value = event0['DeviceDnsName'];
      }
      // Set source entity
      sourceEntityFields.value = cols;
      if (event0?.TagEvent?.AdditionalInfo?.sourceEntity) {
        sourceEntity.value = event0?.TagEvent?.AdditionalInfo?.sourceEntity;
      }
      dialog.value = true;

      recentTags.value = await retrieveRecentTags();
    },
  );
});
// Watch
watch(selectedTags, function () {
  updateModifications();
});
</script>
