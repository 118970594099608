/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.scss';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { VTreeview } from 'vuetify/labs/VTreeview';
// https://github.com/vuetifyjs/vuetify/issues/12224
import Ripple from 'vuetify/directives/ripple';

// Composables
import { createVuetify } from 'vuetify';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const vuetify = createVuetify({
  theme: {
    defaultTheme: 'light',
  },
  components: {
    VTreeview,
  },
  directives: {
    Ripple,
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

export default vuetify;
