// NOTE: Dan - globalThis.crypto is now the defacto standard?! or use node:crypto?!
// would somebody please think of the children..
import { createHash, getRandomValues } from 'crypto-browserify';

/*
  https://stackoverflow.com/a/2117523
 */
export const generateUuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (globalThis.crypto.getRandomValues(new Uint8Array(1))[0] &
        (15 >> (c / 4)))
    ).toString(16),
  );
};

// Takes md5 of given string and returns in uuid format
export const createQueryId = ({ cluster, database, query }) => {
  const hash = createHash('md5');
  const s = [cluster, database, query].join(':');
  hash.update(s);
  let hs = hash.digest('hex');
  hs =
    hs.substring(0, 8) +
    '-' +
    hs.substring(8, 12) +
    '-' +
    hs.substring(12, 16) +
    '-' +
    hs.substring(16, 20) +
    '-' +
    hs.substring(20, 32);
  return hs;
};

export const generateEventId = (prefix) => {
  const len = (32 - prefix.length) / 2;
  const s = Array.from(getRandomValues(new Uint8Array(len)))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  const sId = prefix + s;
  return sId;
};

export const generateURL = (path) =>
  `${window.location.origin}${window.location.pathname}${path}`;

export const isEmptyValue = (val) => {
  return val === '' || val === undefined || val === null;
};

export const extractEventId = (idStr) => idStr.split('&&&&')[0];

export const capitaliseFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const MINS_MS = 60 * 1000;
const HOURS_MS = MINS_MS * 60;
const DAYS_MS = HOURS_MS * 24;

export const timeAgo = ({ minutes = 0, hours = 0, days = 0 }) => {
  return new Date(
    Date.now() - minutes * MINS_MS - hours * HOURS_MS - days * DAYS_MS,
  );
};

export class TimeRange {
  startTime: Date | undefined;
  endTime: Date | undefined;
  constructor(startTime?: Date, endTime?: Date) {
    this.startTime = startTime;
    this.endTime = endTime;
  }

  getStartTime() {
    return this.startTime;
  }

  getEndTime() {
    return this.endTime;
  }

  getText() {
    if (this.startTime && this.endTime)
      return `${this.startTime?.toISOString().substring(0, 16)}Z - ${this.endTime?.toISOString().substring(0, 16)}Z`;
  }
}

export class TimeAgoRange extends TimeRange {
  startTimeAgo: { days?: number; minutes?: number; hours?: number };
  endTimeAgo: { days?: number; minutes?: number; hours?: number };

  constructor(startTimeAgo, endTimeAgo = { days: 0 }) {
    super(undefined, undefined);
    this.startTimeAgo = startTimeAgo;
    this.endTimeAgo = endTimeAgo;
  }

  getStartTime() {
    return timeAgo(this.startTimeAgo);
  }

  getEndTime() {
    return timeAgo(this.endTimeAgo);
  }

  getText() {
    const shortForm = (value) => {
      if (value?.minutes > 0) {
        return `${value.minutes} minutes`;
      } else if (value?.days > 0) {
        return `${value.days} days`;
      } else if (value?.hours > 0) {
        return `${value.hours} hours`;
      } else {
        return 'now';
      }
    };
    const startText = shortForm(this.startTimeAgo);
    const endText = shortForm(this.endTimeAgo);

    if (endText === 'now') {
      return `Last ${startText}`;
    }
    return `${startText} ago - ${endText} ago`;
  }
}

export const toAgCellClassRules = (colName, cellColorRules) => {
  const rules = {};
  Object.keys(cellColorRules).forEach((k) => {
    const v = cellColorRules[k];
    if (v?.operator === 'eq') {
      rules[k] = (params) => params.data[colName] == v?.val;
    } else if (v?.operator === 'in') {
      rules[k] = (params) => v?.vals.includes(params.data[colName]);
    }
  });
  return rules;
};

export const stripTaggingCode = (q) => {
  q = q.replace(/.*\/\/ == End DART tagging functions ==\n/s, '');
  q = q.replace(/\| invoke addTaggingFields\('.*'\)/, '');
  q = q.replace(/.*\/\/\/ YOUR QUERY STARTS HERE - change query below\n/s, '');
  q = q.replace(/\/\/\/ YOUR QUERY ENDS HERE.*/s, '');
  return q;
};

export const copyParams = (inParams) => {
  return JSON.parse(JSON.stringify(inParams));
};

export const initParams = (inParams, template) => {
  const copyParams = { ...inParams };
  for (const [k, v] of Object.entries({
    ...template.fields,
    ...template.params,
  })) {
    if (v?.type === 'match' && typeof copyParams[k] === 'object') {
      copyParams[k] = '';
    }
  }
  return copyParams;
};

export const compress = async (
  str: string,
  encoding = 'gzip' as CompressionFormat,
): Promise<ArrayBuffer> => {
  const byteArray = new TextEncoder().encode(str);
  const cs = new CompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  return new Response(cs.readable).arrayBuffer();
};
