<template>
  <v-snackbar
    v-model="showSnackbar"
    :timeout="options.timeout"
    location="top"
  >
    <v-icon
      :color="options.color"
      start
    >
      {{ options.icon }}
    </v-icon>
    {{ options.message }}
    <template #actions>
      <v-btn
        v-if="options.link"
        variant="text"
        color="blue-grey"
        class="text-blue-grey-lighten-3"
        :href="options.link"
        target="_blank"
      >
        {{ options.linkText }}
        <v-icon end> mdi-open-in-new </v-icon>
      </v-btn>
      <v-btn
        variant="text"
        :color="options.color"
        @click="onCloseSnackbar"
      >
        Dismiss
        <v-icon end> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { eventBus } from '@/main';
import { onMounted, reactive, ref } from 'vue';

/*
 * Private Component data
 */
type SnackBarOpts = {
  timeout?: number;
  color?: string;
  icon?: string;
  message?: string;
  link?: string;
  linkText?: string;
};
const showSnackbar = ref<boolean>(false);
const options = ref<SnackBarOpts>({ timeout: 10 });
const queue = ref<string[]>([]);
const handle = ref(null);
const pause = ref<number>(200);
const data = reactive({
  showSnackbar,
  options,
  queue,
  handle,
  pause,
});
/*
 * Computed Functions
 */
const clearSnackbarState = () => {
  if (handle.value) {
    clearTimeout(data.handle);
    data.handle = null;
  }
  data.showSnackbar = false;
};
const enqueue = (snackbar: string) => {
  data.queue.push(snackbar);
  if (data.handle === null) {
    showNextSnackbar();
  }
};
const onCloseSnackbar = () => {
  clearSnackbarState();
  data.handle = setTimeout(() => {
    showNextSnackbar();
  }, data.pause);
};
const showNextSnackbar = () => {
  clearSnackbarState();
  if (queue.value.length === 0) {
    return;
  }
  data.options = data.queue.shift();
  data.handle = setTimeout(() => {
    showNextSnackbar();
  }, data.options.timeout + data.pause);
  data.showSnackbar = true;
};
const emit = defineEmits<{
  update: [cluster: string, database: string];
}>();
/**
 * Lifecycle
 */
onMounted(() => {
  eventBus.$on('show:snackbar', (attrs) => {
    enqueue({
      color: 'accent',
      icon: 'mdi-information',
      message: '',
      timeout: 5000,
      ...attrs,
    });
  });
});
</script>
