import { Router, createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/store/auth';
import { VueNavigationClient } from './helper';

import OpenTriage from '@/views/OpenTriage.vue';
import QueryEditor from '@/views/QueryEditor.vue';
import Welcome from '@/views/Welcome.vue';
import ShareQuery from '@/views/ShareQuery.vue';
import TagQuery from '@/views/TagQuery.vue';
import TagHashQuery from '@/views/TagHashQuery.vue';
import TagRowQuery from '@/views/TagRowQuery.vue';
import SavedQuery from '@/views/SavedQuery.vue';
import SavedQueryEditor from '@/views/SavedQueryEditor.vue';
import SuppressionEditor from '@/views/SuppressionEditor.vue';
import FunctionEditor from '@/views/FunctionEditor.vue';
import ExportImport from '@/views/ExportImport.vue';

// special routes
const unmatched = '/:pathMatch(.*)*';
const unguarded = ['/mte/tim', '/login', '/logout'];

const routes = [
  {
    path: '/functions/',
    name: 'FunctionEditor',
    component: FunctionEditor,
  },
  {
    path: '/queries/',
    name: 'QueryEditor',
    component: QueryEditor,
  },
  {
    path: '/savedQueries/',
    name: 'SavedQueryEditor',
    component: SavedQueryEditor,
  },
  {
    path: '/suppressions/',
    name: 'SuppressionEditor',
    component: SuppressionEditor,
  },
  {
    path: '/view/:uuid',
    name: 'OpenTriage',
    component: OpenTriage,
    props: true,
  },
  {
    path: '/share/:uuid',
    name: 'ShareQuery',
    component: ShareQuery,
    props: true,
  },
  {
    path: '/tag/:cluster/:database/:table/:col/:id',
    name: 'TagQuery',
    component: TagQuery,
    props: true,
  },
  {
    path: '/taghash/:cluster/:database/:id',
    name: 'TagHashQuery',
    component: TagHashQuery,
    props: true,
  },
  {
    path: '/tagrow/',
    name: 'TagRowQuery',
    component: TagRowQuery,
  },
  {
    path: '/savedQuery/:uuid',
    name: 'SavedQuery',
    component: SavedQuery,
    props: true,
  },
  {
    path: '/exportimport/',
    name: 'Export Import',
    component: ExportImport,
  },
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },
];

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const client = new VueNavigationClient(router);

// set up auth and guard routes
router.beforeEach(async (to, from, next) => {
  // 404
  if (to.matched[0]?.path === unmatched) {
    return next();
  }
  // guarded
  const guarded = unguarded.every((path) => path !== to.path);
  if (guarded) {
    // initialized
    if (!auth.initialized) {
      await auth.initialize(client);
    }

    // authorised
    if (auth.account) {
      return next();
    }

    // unauthorised
    try {
      await auth.login();
      return next();
    } catch (err) {
      return next(false);
    }
  }

  // unguarded
  next();
});

export default router;
