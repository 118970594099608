import type {
  AccountInfo,
  AuthenticationResult,
  PopupRequest,
  SilentRequest,
} from '@azure/msal-browser';
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  NavigationClient,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  config as msalConfig,
  scopes_api,
  scopes_mte_api,
  scopes_kusto,
  scopes_default,
} from '@/config/auth';

// type
export type MaybeAccount = AccountInfo | null;

/**
 * MSAL instance
 */
const config = msalConfig();
export const msal = new PublicClientApplication(config);

/**
 * Auth service
 */
export const Auth = {
  /**
   * Initialize and return active account
   */
  async initialize(client?: NavigationClient): Promise<MaybeAccount> {
    // start msal
    await msal.initialize();
    await msal.handleRedirectPromise();

    // hook into application router
    if (client) {
      msal.setNavigationClient(client);
    }

    // grab and set account if in session
    const accounts = msal.getAllAccounts();
    if (accounts?.length) {
      this.setAccount(accounts[0]);
    }

    // return any active account
    return msal.getActiveAccount();
  },

  /**
   * Login
   */
  async login(): Promise<MaybeAccount> {
    const scopes: Array<string> = scopes_default;
    const request: PopupRequest = {
      redirectUri: config.auth.redirectUri,
      scopes,
    };
    return msal
      .loginPopup(request)
      .then((result) => {
        // set active account
        return this.setAccount(result.account);
      })
      .catch((error: BrowserAuthError) => {
        // if we get stuck, clear session and attempt to log in again
        if (error.errorCode === 'interaction_in_progress') {
          this.reset();
          return this.login();
        }
        throw new Error(error.errorMessage);
      });
  },

  /**
   * Logout
   */
  async logout() {
    return msal.logoutPopup({
      // required to make the application return to the home page
      mainWindowRedirectUri: '/',
    });
  },

  /**
   * Get token for api
   */
  async getToken() {
    const activeAccount: MaybeAccount = await Auth.getAccount();
    const scopes: Array<string> = scopes_api;
    const request: SilentRequest = {
      scopes,
      authority: `https://login.microsoftonline.com/${activeAccount?.tenantId}`,
    };
    return msal
      .acquireTokenSilent(request)

      .catch(async (error: unknown) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msal.acquireTokenPopup(request);
        }
        throw error;
      })
      .then((result: AuthenticationResult) => {
        return result.accessToken;
      });
  },

  /**
   * Get token for MTE API
   */
  async getTokenMte() {
    const scopes: Array<string> = scopes_mte_api;
    const request: SilentRequest = {
      scopes,
    };
    return msal
      .acquireTokenSilent(request)

      .catch(async (error: unknown) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msal.acquireTokenPopup(request);
        }
        throw error;
      })
      .then((result: AuthenticationResult) => {
        return result.accessToken;
      });
  },
  /**
   * Get token for Kusto
   */
  async getTokenKusto() {
    const scopes: Array<string> = scopes_kusto;
    const request: SilentRequest = {
      scopes,
    };
    return msal
      .acquireTokenSilent(request)

      .catch(async (error: unknown) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msal.acquireTokenPopup(request);
        }
        throw error;
      })
      .then((result: AuthenticationResult) => {
        return result.accessToken;
      });
  },
  /**
   * Set active account
   * @private
   */
  setAccount(account: MaybeAccount): MaybeAccount {
    msal.setActiveAccount(account);
    return account;
  },
  /**
   * Get active account
   * @private
   */
  async getAccount(): Promise<MaybeAccount> {
    return msal.getActiveAccount();
  },

  /**
   * Escape hatch when msal gets stuck
   * @private
   */
  reset() {
    sessionStorage.clear();
  },
};
