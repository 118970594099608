<template>
  <v-container
    class="fill-height"
    fluid
  >
    <div :class="isTreeOpen ? 'tim-main-view-2' : 'tim-main-view-1'">
      <v-row>
        <v-col>
          <h2>Query Manager</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            class="ml-1"
            variant="flat"
            size="small"
            @click="onClickCreateQuery"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-plus-circle
            </v-icon>
            Create
          </v-btn>
          <v-btn
            class="ml-1"
            variant="flat"
            size="small"
            :disabled="selectedDelete.length === 0 || selectedHasManaged"
            @click="onClickDelete"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-delete
            </v-icon>
            Delete ({{ selectedDelete.length }})
          </v-btn>
          <v-btn
            v-if="showDeleted"
            class="ml-1"
            variant="flat"
            size="small"
            :disabled="selectedRestore.length === 0 || selectedHasManaged"
            @click="onClickRestore"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-auto-fix
            </v-icon>
            Restore ({{ selectedRestore.length }})
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredQueries"
          :search="search"
          :loading="loading"
          show-select
          item-key="uuid"
        >
          <template #item.name="{ item }">
            <a
              v-if="item.isDeleted !== true"
              href="javascript:void(0)"
              @click="onClickEditQuery(item)"
              >{{ item.name }}</a
            >
            <span
              v-else
              class="lighten-text-grey-1"
              >{{ item.name }}</span
            >
          </template>
          <template #top>
            <v-switch
              v-model="showDeleted"
              label="Show deleted"
              :style="{ paddingLeft: '15px' }"
            />
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Filter"
              class="mx-4"
            />
          </template>
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-checkbox-btn
              :value="isSelected"
              @input="select($event)"
            />
          </template>
          <template #item.isHidden="{ item }">
            <v-switch
              :model-value="isQueryHidden(item.uuid)"
              @update:model-value="onHideQuery(item.uuid, $event)"
            />
          </template>
        </v-data-table>
      </v-row>
    </div>
    <CreateQueryDialog
      v-if="createQueryDialog"
      :dialog="createQueryDialog"
      :edit-query="editQuery"
      :queryEditMode="createQueryDialog"
      @reload:queries="onReloadQueries"
      @update:dialog="onCloseQueryDialog"
    />
  </v-container>
</template>

<script setup lang="ts">
import CreateQueryDialog from '@/components/CreateQueryDialog.vue';
import { queryDelete, queryRestore } from '@/services/apiClient';

import { ref, computed, onMounted } from 'vue';
import { useStore } from '@/store/store';
import { QueryTemplate } from '@/renderer/kusto_queries';

// Store
const store = useStore();
// Data
const queries = ref([]);
const createQueryDialog = ref(false);
const editQuery = ref<QueryTemplate>();
const showDeleted = ref(false);
const search = ref(null);
const headers = ref([
  { title: 'Name', key: 'name' },
  { title: 'Type', key: 'queryType' },
  { title: 'Menu text', key: 'menu' },
  { title: 'Last Updated', key: 'updated' },
  { title: 'Path', key: 'path' },
  { title: 'Cluster', key: 'cluster' },
  { title: 'Managed', key: 'isManaged' },
  { title: 'Hidden', key: 'isHidden' },
]);
const selected = ref([]);

// Computed
const loading = computed(() => {
  if (queries.value.length <= 0) {
    return true;
  }
  return false;
});

const filteredQueries = computed(() => {
  if (showDeleted.value) {
    return queries.value;
  } else {
    return queries.value.filter((query) => query.isDeleted !== true);
  }
});

const selectedRestore = computed(() => {
  return selected.value.filter((query) => query.isDeleted === true);
});

const selectedDelete = computed(() => {
  return selected.value.filter((query) => query.isDeleted !== true);
});

const selectedHasManaged = computed(() => {
  return selected.value.some((query) => query.isManaged === true);
});

const isTreeOpen = computed(() => {
  return process.env.VUE_APP_TREE_MODE === 'open';
});

// Actions
const getQueries = (reload: boolean) => {
  return store.dispatch('queries/getQueries', reload);
};

const reloadQueries = () => {
  return store.dispatch('queries/reloadQueries');
};

const updateQueryOption = ({ uuid, queryOption }) => {
  return store.dispatch('queries/reloadQueries', { uuid, queryOption });
};

// Getters
const getQueryOption = (uuid: string) => {
  store.getters['queries/getQueryOption'](uuid);
};
// Methods
const onReloadQueries = async function () {
  queries.value = await getQueries(true);
};

const onClickEditQuery = (query) => {
  editQuery.value = { ...query };
  createQueryDialog.value = true;
};

const onClickCreateQuery = () => {
  editQuery.value = new QueryTemplate({ query: '// Query Start' });
  createQueryDialog.value = true;
};

const onCloseQueryDialog = () => {
  editQuery.value = null;
  createQueryDialog.value = false;
};

const onHideQuery = async function (uuid, value) {
  await updateQueryOption({
    uuid: uuid,
    queryOption: { hide: value },
  });
};

const onClickRestore = async function () {
  await Promise.all(
    selectedRestore.value.map((query) => queryRestore(query.uuid)),
  );
  await onReloadQueries();
  await reloadQueries();
  selected.value = [];
};

const onClickDelete = async function () {
  await Promise.all(
    selectedDelete.value.map((query) => queryDelete(query.uuid)),
  );
  await onReloadQueries();
  await reloadQueries();
  selected.value = [];
};

const isQueryHidden = function (uuid) {
  return getQueryOption(uuid)?.hide === true;
};

// Mounted
onMounted(async () => {
  await onReloadQueries();
});
</script>

<style></style>
