import { store } from '@/store/store';
import { eventBus } from '@/main';
import router from '@/router';
import { deleteDataStore, saveDataStore } from '@/renderer/localStorage';
import { runKustoQueryPoll } from '@/renderer/queries';
import { stripTaggingCode } from './utils';

export const createNewTemplateQueryComponent = async (
  title,
  queryTemplate,
  params,
  parentUuid,
  autoExecuteQuery = false,
  makeActive = false,
  investigationView = false,
) => {
  // Deep clone the params (support for objects/arrays)
  //let cloneParams = JSON.parse(JSON.stringify(params));
  let cloneParams = { ...params };
  cloneParams = queryTemplate.expandParams(cloneParams);

  const isDataComplete = queryTemplate.isDataComplete(cloneParams);
  const uuid = await store.dispatch('displayComponent/createDisplayComponent', {
    componentName: 'TemplateQueryResult',
    title: title,
    params: {
      inParams: cloneParams,
      queryTemplate: queryTemplate,
    },
    parentUuid: parentUuid,
    state: {
      isVisited: false,
      error: null,
      rowCount: null,
      isExecuting: false,
      editQuery: !autoExecuteQuery || !isDataComplete,
      investigationView: investigationView,
    },
    rowDataTrigger: null,
  });

  if (autoExecuteQuery && isDataComplete) {
    await runTemplateQuery(uuid);
  }

  if (makeActive || !isDataComplete) {
    await router.push({ name: 'OpenTriage', params: { uuid: uuid } });
  }
};

export const createNewQuerySetComponent = async (
  title,
  querySet,
  params,
  parentUuid,
  autoExecuteQuery = false,
  makeActive = false,
  investigationView = false,
) => {
  let timeElapsed = Date.now();

  // Deep clone the params (support for objects/arrays)
  let cloneParams = querySet.expandParams(params);
  console.log(
    new Date(timeElapsed).toTimeString(),
    'inside createNewQuerySetComponent start',
  );
  // Expand out the mustache terms embedded within the param values
  cloneParams = querySet.expandParams(cloneParams);
  const uuid = await store.dispatch('displayComponent/createDisplayComponent', {
    componentName: 'QuerySetResults',
    title: title,
    params: {
      inParams: cloneParams,
      querySet: querySet,
    },
    parentUuid: parentUuid,
    state: {
      isVisited: false,
      error: null,
      rowCount: null,
      isExecuting: false,
      editQuery: !autoExecuteQuery,
      investigationView,
    },
    rowDataTrigger: null,
  });
  timeElapsed = Date.now();
  console.log(new Date(timeElapsed).toTimeString(), 'await uuid=', uuid);

  timeElapsed = Date.now();
  console.log(
    new Date(timeElapsed).toTimeString(),
    'starting createNewTemplateQueryComponent',
  );
  // Create new child component for every query in query set
  querySet.queryIds.map(async (qid) => {
    const qt = store.getters['queries/getTemplate'](qid);
    if (qt) {
      await createNewTemplateQueryComponent(
        qt.summary,
        qt,
        qt.getDefaultParams(),
        uuid,
        false,
        false,
        investigationView,
      );
    }
  });
  timeElapsed = Date.now();
  console.log(
    new Date(timeElapsed).toTimeString(),
    'finished iterate queryids',
  );
  if (makeActive) {
    await router.push({ name: 'OpenTriage', params: { uuid: uuid } });
  }
};

export const convertToCustomQuery = async (uuid, queryTemplate, params) => {
  await store.dispatch('displayComponent/convertDisplayComponent', {
    uuid: uuid,
    componentName: 'KustoQueryResult',
    params: {
      query: queryTemplate.buildQuery(params),
      cluster: queryTemplate.buildCluster(params),
      database: queryTemplate.buildDatabase(params),
      functions: queryTemplate.functions,
    },
    state: {
      isVisited: false,
      error: null,
      rowCount: null,
      isExecuting: false,
    },
  });
};

export const defaultNewQuery = () =>
  process.env.VUE_APP_IS_DART === 'true'
    ? `// Do not remove this comment - {{>dartFunctions}}
declare query_parameters(StartTime:datetime, EndTime:datetime);
/// YOUR QUERY STARTS HERE - change query below
SnapEventLog
| take 10
/// YOUR QUERY ENDS HERE
| invoke addTaggingFields()
`
    : `declare query_parameters(StartTime:datetime, EndTime:datetime);
macro-expand WcdScrubbedData as X
(
  /// YOUR QUERY STARTS HERE
  X.CreateProcessEvents
  | where ReportArrivalTimeUtc between (StartTime .. EndTime)
  | take 1
  /// YOUR QUERY ENDS HERE
  | extend EventTime=ReportTime, EventId=ReportGuid, Cluster=X.$current_cluster_endpoint
)`;

export const createNewQueryComponent = async (
  title,
  query = defaultNewQuery(),
  cluster = '',
  database = '',
  functions = [],
  autoExecuteQuery = false,
) => {
  if (!cluster) {
    const dartCluster = store.getters['engagement/getCluster'];
    cluster = dartCluster;
  }
  if (!database) {
    const dartDb = store.getters['engagement/getDatabase'];
    database = dartDb;
  }
  const uuid = await store.dispatch('displayComponent/createDisplayComponent', {
    componentName: 'KustoQueryResult',
    title: title,
    params: {
      query: query,
      cluster: cluster,
      database: database,
      functions,
    },
    parentUuid: null,
    state: {
      isVisited: false,
      error: null,
      rowCount: null,
      isExecuting: false,
      editQuery: !autoExecuteQuery,
    },
    rowDataTrigger: null,
  });
  if (autoExecuteQuery) {
    await runNewQuery(uuid, query, cluster, database);
  }
  await router.push({ name: 'OpenTriage', params: { uuid: uuid } });
  return uuid;
};

export const createSavedQueryComponent = async (name, queryId) => {
  const uuid = await store.dispatch('displayComponent/createDisplayComponent', {
    componentName: 'SavedQueryResult',
    title: `Saved query - ${name}`,
    params: {
      queryId: queryId,
    },
    parentUuid: null,
    state: {
      isVisited: false,
      error: null,
      rowCount: null,
      isExecuting: false,
    },
    rowDataTrigger: null,
  });
  await router.push({ name: 'OpenTriage', params: { uuid: uuid } });
};

export const runNewQuery = async (
  uuid,
  query,
  cluster,
  database,
  additionalParameters = {},
) => {
  await store.dispatch('displayComponent/updateComponentState', {
    uuid: uuid,
    isExecuting: true,
    isVisited: false,
    error: null,
  });

  try {
    const result = await runKustoQueryPoll(
      cluster,
      database,
      query,
      additionalParameters,
    );

    await store.dispatch('displayComponent/updateComponentState', {
      uuid: uuid,
      isExecuting: false,
      executionTime: result?.queryInfo.ExecutionTime,
      cpuUsage: result?.queryInfo?.resource_usage?.cpu['total cpu'],
      memoryUsage: result?.queryInfo?.resource_usage?.memory?.peak_per_node,
      rowCount: result?.data.length,
    });

    eventBus.$emit('update:kusto-results', { uuid: uuid });

    if (result.data.length === 0) {
      await saveDataStore(uuid, []);
      await store.dispatch('displayComponent/triggerComponentRowData', uuid);
      console.info('No results found...');
      return;
    }

    await saveDataStore(uuid, result.data);
    await store.dispatch('displayComponent/triggerComponentRowData', uuid);
  } catch (err) {
    await store.dispatch('displayComponent/updateComponentState', {
      uuid: uuid,
      error: err,
      isExecuting: false,
    });

    await deleteDataStore(uuid);
    await store.dispatch('displayComponent/triggerComponentRowData', uuid);
  }
};

export const resolveQuery = async (uuid, doStripTags = false) => {
  let { queryTemplate, inParams, query, cluster, database } =
    store.getters['displayComponent/getComponentParams'](uuid);
  if (queryTemplate?.buildCluster) {
    cluster = queryTemplate.buildCluster(inParams);
    database = queryTemplate.buildDatabase(inParams);
    query = queryTemplate.buildQuery(inParams);
    const functions = queryTemplate.buildFunction();
    query = `${functions}\n${query}`;
  }
  if (!cluster || !database || !query) {
    return {
      cluster: '',
      database: '',
      query: '',
    };
  }
  // Store cluster name in simple form
  cluster = cluster.replace('https://', '');
  cluster = cluster.replace(/\.kusto\.windows\.net\/?/, '');
  return {
    cluster,
    database,
    query: doStripTags ? stripTaggingCode(query) : query,
  };
};

export const runTemplateQuery = async (uuid) => {
  const q = await resolveQuery(uuid);
  await runNewQuery(uuid, q.query, q.cluster, q.database);
};

export const applyQuerySetParams = async (uuid) => {
  // Get child template components
  const children = store.getters['displayComponent/getChildComponents'](uuid);
  const qsParams =
    store.getters['displayComponent/getComponentParams'](uuid).inParams;
  // Apply query set params to child components
  children.forEach((c) => {
    const u = c.componentUuid;
    const oldParams = c?.params?.inParams || {};
    const mparams = { ...oldParams, ...qsParams };
    store.dispatch('displayComponent/updateComponentParams', {
      uuid: u,
      inParams: mparams,
    });
    eventBus.$emit('update:template-params', {
      uuid: u,
      updateParams: mparams,
    });
  });
};

// Runs all child template components in given instance of query set component
export const runQuerySet = async (uuid) => {
  // Get child template components
  const children = store.getters['displayComponent/getChildComponents'](uuid);
  //const qsParams = store.getters['displayComponent/getComponentParams'](uuid).inParams;
  // Apply query set params to child components
  //children.forEach(c => {
  //  const u = c.componentUuid;
  //  const oldParams = c?.params?.inParams || {};
  //  const mparams = { ...oldParams, ...qsParams };
  //  console.log('runQuerySet() qsparams=', qsParams, 'oldParams=', oldParams, 'mparams=', mparams);
  //  store.dispatch('displayComponent/updateComponentParams', {uuid: u, inParams: mparams});
  //  eventBus.$emit('update:template-params', {uuid: u, updateParams: mparams});
  //});
  // Run all child components
  children.forEach((c) => {
    const u = c.componentUuid;
    runTemplateQuery(u);
  });
};

export const templateQueriesAsObject = (queries) => {
  let lookup = {};
  let result = [];
  if (queries.length > 0) {
    queries.forEach((query) => {
      let currentList = result;
      query.path.forEach((path) => {
        if (!(path in lookup)) {
          lookup[path] = {
            title: path,
            items: [],
          };
          currentList.push(lookup[path]);
        }
        currentList = lookup[path].items;
      });
      currentList.push(query);
    });
    return result;
  }
};
