import { generateUuidv4 } from '@/renderer/utils';
import localforage from 'localforage';
import { reactive } from 'vue';
import {
  ColumnView,
  columnviewsActions,
  columviewsGetters,
  columviewsSetters,
} from './stateInit';

const columnViewStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  storeName: 'column_views',
});

const state = reactive({
  columnViews: {},
});

const getters: columviewsGetters = {
  getColumnView: (state) => (uuid) => {
    return state.columnViews[uuid];
  },
  getAllColumnViews: (state) => {
    return Object.values(state.columnViews).sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );
  },
};

const actions: columnviewsActions = {
  async addColumnView({ commit }, { name, columnState }) {
    const uuid = generateUuidv4();

    const columnView = {
      uuid,
      name,
      columnState,
    };

    await columnViewStore.setItem(uuid, columnView);
    commit('setColumnView', columnView);

    return uuid;
  },
  async updateColumnView({ commit, state }, { uuid, ...columnView }) {
    columnView = {
      ...state.columnViews[uuid],
      ...columnView,
    };

    await columnViewStore.setItem(uuid, columnView);
    commit('setColumnView', columnView);
  },
  async removeColumnView({ commit }, uuid) {
    await columnViewStore.removeItem(uuid);
    commit('removeColumnView', uuid);
  },
  async loadAllColumnViews({ commit }) {
    const columnViews = {};
    await columnViewStore.iterate((columnView: ColumnView) => {
      columnViews[columnView.uuid] = columnView;
    });

    commit('setColumnViews', columnViews);
  },
};

const mutations: columviewsSetters = {
  setColumnView(state, columnView) {
    state.columnViews[columnView.uuid] = columnView;
  },
  setColumnViews(state, columnViews) {
    state.columnViews = columnViews;
  },
  removeColumnView(state, uuid) {
    delete state.columnViews[uuid];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
