<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    :scrim="false"
    :retain-focus="false"
    no-click-animation
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="popup-header">
          <span class="text-h5">Customise Spectre Event</span>
        </v-card-title>
        <v-card-subtitle>
          {{ row_events.length }} event(s) selected
        </v-card-subtitle>
        <v-card-text>
          <v-container
            fluid
            no-gutters
          >
            <v-row>
              <v-col cols="12">
                <v-row>
                  <div v-html="getAnchorEventTitle" />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="spectreEventTitle"
                    label="New Event Title"
                  />
                </v-row>
              </v-col>
              <v-row>
                <v-col cols="8">
                  <v-checkbox
                    v-model="rawToTable"
                    label="Keep Raw activity as JSON"
                  />
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="updateAnchorEvent()"
                  >
                    Refresh Spectre
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>

            <v-row>
              <p>
                <br />
                Spectre Event start/end times are populated from selected
                events, or selected from Anchor event.<br />
                Will attempt to match Tags below (including MITRE) against
                Spectre tags, Irwin tags will not be modified.<br />
              </p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-text-field
                    v-model="comment"
                    label="Additional notes for Spectre event (supports mustache templating) (optional)"
                  />
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-autocomplete
                    v-model="selectedTags"
                    v-model:search="search"
                    :items="tags"
                    chips
                    closable-chips
                    hide-details
                    hide-selected
                    label="Tags to be added to Spectre (tag match, or description)"
                    multiple
                  >
                    <template
                      v-if="true"
                      #prepend-item
                    >
                      <v-list-item
                        ripple
                        @click="onClickTagSelect()"
                      >
                        <v-list-item-title>
                          {{ search || 'Type to add new tag...' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Custom tag</v-list-item-subtitle>
                      </v-list-item>
                      <v-divider class="mt-2" />
                    </template>
                  </v-autocomplete>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <div v-html="getNewEventUrl" />
                </v-row>
              </v-col>
            </v-row>
            <ul
              v-for="(error, index) in errors"
              :key="`error-${index}`"
              class="text-error"
            >
              <li>{{ error }}</li>
            </ul>
            <ul
              v-for="(modification, index) in modifications"
              :key="`mod-${index}`"
            >
              <li>{{ modification }}</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="onSaveSpectreEvent()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { eventBus } from '@/main';
import {
  Actions,
  presetTags,
  sourceTags,
  retrieveRecentTags,
  tagsFromData,
} from '@/renderer/tags';
import { extractEventId } from '@/renderer/utils';
import { mapGetters } from 'vuex';
import {
  createTags,
  createSpectreEvent,
  getSpectreEngagementAnchor,
} from '@/services/apiClient';
import { mustacheExpand } from '@/renderer/kusto_queries';

import { reactive, ref, computed, watch, onMounted } from 'vue';
import { useStore } from '@/store/store';
import { VForm } from 'vuetify/components';
let _onSuccess = null;

// Data
const dialog = ref(false);
const form = ref<VForm>();
const anchorSpectreEvent = ref(null);
const anchorSpectreTags = ref([]);
const spectreEventTitle = ref('');
const comment = ref(null);
const search = ref(null);
const row_events = ref([]);
const recentTags = ref([]);
const selectedTags = ref([]);
const pinnedTags = ref([]);
const valid = ref(true);
const errors = ref([]);
const modifications = ref([]);
const _investigationView = ref(false);
const _manualEntryView = ref(false);
const RawActivity = ref('');
const eventTime = ref('');
const eventTimeCol = ref('');
const timeFields = ref('');
const tagAction = ref(null);
const newSpectreEventId = ref(null);
const rawToTable = ref(false);

// Store
const store = useStore();
// Computed
const getEngagementName = computed(() => {
  return store.getters['engagement/getEngagement'];
});

const tags = computed(() => {
  if (tagAction.value === Actions.Remove) {
    return [...new Set([...selectedTags.value, ...existingTags.value])];
  }
  return [
    ...new Set([
      ...selectedTags.value,
      ...existingTags.value,
      ...recentTags.value,
      ...presetTags(),
      ...sourceTags(),
    ]),
  ];
});

const existingTags = computed(() => {
  return row_events.value.map((data) => tagsFromData(data)).flat();
});

const existingTagsObj = computed(() => {
  return row_events.value.reduce((obj, data) => {
    tagsFromData(data).forEach((tag) => {
      if (!obj[tag]) {
        obj[tag] = [];
      }
      obj[tag].push(data);
    });
    return obj;
  }, {});
});

const actions = computed(() => {
  return Actions;
});

const unsavedEvents = computed(() => {
  return row_events.value.filter((data) => data.TagEvent?.IsSaved !== true);
});

const getAnchorEventTitle = computed(() => {
  const title = anchorSpectreEvent.value?.title || 'Fetching anchor event ...';
  const url = `${process.env.VUE_APP_SPECTRE_URL}#/event/${anchorSpectreEvent.value?.id}`;
  return `<a href="${url}" target="_blank">${title}</a>`;
});

const getNewEventUrl = computed(() => {
  if (newSpectreEventId.value === null) {
    return 'No event create yet ...';
  }
  const url = `${process.env.VUE_APP_SPECTRE_URL}#/event/${newSpectreEventId.value}`;
  return `<a href="${url}" target="_blank">See New Event!</a>`;
});

// Methods
const onClickTagSelect = function () {
  selectedTags.value = [...new Set([search.value, ...selectedTags.value])];
  search.value = '';
};

const validate = function () {
  errors.value = [];

  if (spectreEventTitle.value === '') {
    errors.value.push('Event title is required');
  }

  if (row_events.value.length === 0) {
    errors.value.push('No events selected');
  }

  if (anchorSpectreEvent.value == null) {
    errors.value.push('No anchor event found');
  }

  try {
    mustacheExpand(comment.value, events[0]);
  } catch (error) {
    errors.value.push(error);
  }

  return errors.value.length === 0;
};

const updateAnchorEvent = async function () {
  try {
    if (anchorSpectreEvent.value == null) {
      const anchor = await getSpectreEngagementAnchor(getEngagementName.value);
      anchorSpectreEvent.value = anchor || {};
      anchorSpectreTags.value = anchorSpectreEvent.value?.tags || [];
    }
  } catch (error) {
    eventBus.$emit('show:snackbar', {
      message: `Failed to fetch anchor event, check token: ${error}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }
};

const onSaveSpectreEvent = async function () {
  const formValid = await form.value?.validate().then((form) => {
    return form.valid;
  });

  if (!formValid || !validate()) {
    return;
  }

  // get all event times from events in an array, sorted
  var eventTimeMax = null;
  var eventTimeMin = null;

  const eventTimes = [];
  row_events.value.forEach((e) => {
    if (e != null) {
      var et = Date.parse(e.EventTime);
      if (isNaN(et)) {
        errors.value.push(`date parsing error, check time ${e.EventTime}`);
        return;
      }
      if (et < 1000000000000) {
        errors.value.push(`date ${e.EventTime} appears invalid`);
        return;
      }
      eventTimes.push(et);
    }
  });
  eventTimes.sort();
  if (eventTimes.length > 0) {
    eventTimeMin = eventTimes[0];
    eventTimeMax = eventTimes[eventTimes.length - 1];
  } else {
    eventBus.$emit('show:snackbar', {
      message: 'No valid event times found, using Anchor event times instead.',
      color: 'warning',
      icon: 'mdi-alert',
    });
    eventTimeMax = anchorSpectreEvent.value?.lastObservedTime;
    eventTimeMin = anchorSpectreEvent.value?.firstObservedTime;
  }

  // SpectreEventRequest
  const dataForSpectre = {
    anchorEvent: anchorSpectreEvent.value, // must include our anchor event
    comment: comment.value,
    title: spectreEventTitle.value,
    irwinTags: selectedTags, // irwin tags are added to event body
    irwinEvents: JSON.stringify(row_events.value), // raw events as json
    firstObservedTime: Date.parse(eventTimeMin),
    lastObservedTime: Date.parse(eventTimeMax),
    activityToTable: !rawToTable.value,
  };

  eventBus.$emit('show:snackbar', {
    message: `submitting events to Spectre, please wait ...`,
  });

  // send to API
  try {
    const createResponse = await createSpectreEvent(dataForSpectre);
    // this will make the URL show up in the dialog
    newSpectreEventId.value = createResponse.id;
  } catch (error) {
    eventBus.$emit('show:snackbar', {
      message: `Failed to create Spectre event: ${error}`,
      color: 'error',
      icon: 'mdi-alert',
    });
    return;
  }

  // for each event that we sent
  // go through, add the tag 'inSpectre' to the event
  // and submit using createTags
  const spectreTags = row_events.value.map((event) => ({
    eventId: extractEventId(event._id),
    tag: 'inSpectre',
    timestampType: this.eventTimeCol,
    isDeleted: false,
  }));
  await createTags(spectreTags, this.engagementName);

  eventBus.$emit('show:snackbar', {
    message: `${row_events.value.length} events submitted to spectre`,
  });
};

onMounted(() => {
  eventBus.$on(
    'create:spectre-dialogue',
    async ({
      events,
      onSuccess,
      tags,
      timeFields,
      investigationView,
      manualEntryView,
    }) => {
      // Re-initialise variables
      RawActivity.value = '';
      row_events.value = events;

      _onSuccess = onSuccess;
      _investigationView.value = investigationView;
      _manualEntryView.value = manualEntryView;

      // go over events and remove those that have the tag 'inSpectre', or do not have a queryid
      var filteredEvents = row_events.value.filter(
        (data) => !tagsFromData(data).includes('inSpectre'),
      );
      filteredEvents = filteredEvents.filter(
        (data) => data.QueryId != null && data.QueryId != '',
      );

      // if we have filtered some out, update events and send a toast notifying
      var filtered = false;
      if (filteredEvents.length < row_events.value.length) {
        row_events.value = filteredEvents;
        filtered = true;
      }
      if (row_events.value.length === 0) {
        eventBus.$emit('show:snackbar', {
          message:
            'All items are already in Spectre, or do not have a queryId, nothing to do.',
          icon: 'mdi-alert',
        });
        dialog.value = false;
        return;
      }

      if (filtered) {
        eventBus.$emit('show:snackbar', {
          message: `Some items are already in Spectre, or do not have a queryId, they will be ignored.`,
          icon: 'mdi-alert',
        });
        filtered = false;
      }

      timeFields.value = timeFields;
      const event0 = row_events.value[0];
      const cols = Object.keys(event0);
      if (getEngagementName.value) {
        const ts = event0?.TagEvent?.Tags || [];
        selectedTags.value = [...new Set([...tags, ...ts])];
      } else {
        selectedTags.value = [];
      }
      if (events.length >= 1) {
        comment.value = '';
        if (_investigationView.value) {
          eventTimeCol.value = event0?.TimestampType;
          eventTime.value = event0?.Timestamp;
        }
      }
      if (!comment && cols.includes('Note')) {
        comment.value = '{{Note}}';
      }
      errors.value = [];
      modifications.value = [];
      dialog.value = true;

      spectreEventTitle.value = '';
      recentTags.value = await retrieveRecentTags();
    },
  );
});
</script>
