<template>
  <transition name="fade">
    <div
      v-if="isVisible"
      class="popup-modal"
    >
      <div class="window">
        <h2 style="margin-top: 0">
          {{ title }}
        </h2>
        <p>{{ message }}</p>
        <div class="btns">
          <button
            class="cancel-btn"
            @click="_cancel"
          >
            {{ cancelButton }}
          </button>
          <span
            class="ok-btn"
            @click="_confirm"
            >{{ okButton }}</span
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';

const isVisible = ref<boolean>(false);
const title = ref<string>('');
const message = ref<string>('');
const okButton = ref<string>('Ok');
const cancelButton = ref<string>('Cancel');
let resolvePromise;
let rejectPromise;
const data = reactive({
  isVisible,
  title,
  message,
  okButton,
  cancelButton,
});
/*
 * Computed Functions
 */
const open = () => {
  data.isVisible = true;
};
const close = () => {
  data.isVisible = false;
};
type Opts = {
  title: string;
  message: string;
  okButton: string;
  cancelButton: string;
};
const show = (opts: Opts) => {
  data.title = opts.title;
  data.message = opts.message;
  data.okButton = opts.okButton;
  if (opts?.cancelButton) {
    data.cancelButton = opts.cancelButton;
  }
  // Once we set our config, we tell the popup modal to open
  open();
  // Return promise so the caller can get results
  return new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });
};
const _confirm = () => {
  close();
  resolvePromise(true)
};
const _cancel = () => {
  close();
  resolvePromise(false)
  // Or you can throw an error
  // this.rejectPromise(new Error('User canceled the dialogue'))
};
// Exposed
defineExpose({
  show,
});
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
}

.window {
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  color: red;
  text-decoration: underline;
  line-height: 2.5rem;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
