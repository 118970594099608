<template>
  <div>
    <div class="px-3 d-flex">
      <v-divider
        vertical
        style="height: 26px"
      />
      <!--
      <v-btn
        @click="onClickRunQuery"
        text
        small
        :disabled="editQuery"
      >
        <v-icon
          left
          small
        >
          mdi-play
        </v-icon>
        Run Queries
      </v-btn>
      <v-divider
        vertical
        style="height: 26px;"
      ></v-divider>
      -->
      <v-btn
        v-if="!editQuery"
        variant="text"
        size="small"
        @click="onClickEditQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-pencil
        </v-icon>
        Edit
      </v-btn>
      <v-btn
        variant="text"
        size="small"
        color="primary"
        @click="onClickSaveRunQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-play
        </v-icon>
        Run All Queries
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="onClickSaveQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="onClickCancelEdit"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="isInvestigationView"
        variant="text"
        size="small"
        @click.prevent="onDownloadTimeline"
      >
        <v-icon
          start
          size="small"
        >
          mdi-download-circle-outline
        </v-icon>
        Download Timeline
      </v-btn>
    </div>
    <v-form
      v-if="editParams"
      ref="form"
      class="mx-2"
    >
      <v-text-field
        v-model="editTitle"
        label="Summary"
        prepend-icon="mdi-refresh"
        @click:prepend="onClickRefreshTitle"
      />
      <v-row dense>
        <v-col
          v-for="(field, fieldKey) in {
            ...querySet.params,
            ...querySet.fields,
          }"
          :key="fieldKey"
          cols="4"
        >
          <v-select
            v-if="field.type === 'array'"
            v-model="editParams[fieldKey]"
            :items="field.values"
            :hint="field.hint"
            persistent-hint
            :multiple="field.multiple === true"
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span>
            </template>
            <template
              v-if="field.multiple === true"
              #selection="{ item, index }"
            >
              <span
                v-if="index === 0 || editParams[fieldKey].length <= 5"
                class="mr-1"
              >
                {{ item }},
              </span>
              <span
                v-if="index === 1 && editParams[fieldKey].length > 5"
                class="text-grey text-caption mx-1"
              >
                (+{{ editParams[fieldKey].length - 1 }} others)
              </span>
            </template>
            <template
              v-if="field.multiple === true"
              #prepend-item
            >
              <v-list-item
                ripple
                @click="clearFieldSelection(fieldKey, field)"
              >
                <v-list-item-action>
                  <v-icon
                    :color="editParams[fieldKey].length > 0 ? 'indigo darken-4' : ''
                    "
                  >
                    {{
                      editParams[fieldKey].length > 0
                        ? editParams[fieldKey].length === field.values.length
                          ? 'mdi-close-box'
                          : 'mdi-minus-box'
                        : 'mdi-checkbox-blank-outline'
                    }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-select>
          <v-combobox
            v-else-if="field.type === 'match' &&
              typeof getFieldWithCache(fieldKey) === 'object'
            "
            v-model="editParams[fieldKey]"
            :items="getFieldWithCache(fieldKey)"
            item-value="value"
            :item-title="(v) => (v.column ? `${v.column}: ${v.value}` : '')"
            :hint="field.hint"
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
            persistent-hint
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span>
            </template>
          </v-combobox>
          <v-combobox
            v-else-if="field.type === 'multiple'"
            v-model="editParams[fieldKey]"
            :items="getFieldWithCache(fieldKey)"
            :hint="field.hint"
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
            :delimiters="[',', ';']"
            persistent-hint
            multiple
            clearable
            chips
            append-icon=""
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span>
            </template>
          </v-combobox>
          <v-switch
            v-else-if="field.type === 'boolean'"
            v-model="editParams[fieldKey]"
            :label="fieldKey"
            :hint="field.hint"
            persistent-hint
          />
          <v-text-field
            v-else
            v-model.trim="editParams[fieldKey]"
            :hint="field.hint"
            persistent-hint
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <!--
      <v-textarea
        rows="20"
        v-show="viewQuery"
        :value="getQuery"
        readonly>
      </v-textarea>
      -->
    </v-form>
    <div>
      <v-alert
        v-if="error"
        type="error"
        text
        variant="outlined"
      >
        <pre
          class="code"
          style="white-space: pre-wrap"
        >
          {{ error.toString() }}
        </pre>
      </v-alert>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mapActions, mapGetters } from 'vuex';
import {
  createNewQueryComponent,
  createNewTemplateQueryComponent,
  applyQuerySetParams,
  runQuerySet,
} from '@/renderer/displayComponent';
import { QuerySet } from '@/renderer/kusto_queries';
import NewQueryButton from '@/components/NewQueryButton.vue';
import { eventBus } from '@/main';
import { getTimelineSpreadsheet } from '@/services/apiClient';
import { saveAs } from 'file-saver';

import { reactive, ref, computed, watch, onMounted } from 'vue';
import { useStore } from '@/store/store';

// Store
const store = useStore();
// Data
const viewQuery = ref(false);
const query = ref('');
const rules = reactive({
  required: (value) => !!value || 'Required.',
});
const querySnackbar = reactive({
  enabled: false,
  text: null,
  color: null,
});
const editParams = ref(null);
const form = ref<FormData>({});
const editTitle = ref(null);
const convertWarning = ref(false);
const cacheVariable = reactive({});
const autoExecute = ref(true);

// Props
const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});

// Props

// Getters
const getComponentParams = (uuid: string) => {
  return store.getters['displayComponent/getComponentParams'](uuid);
};
const getComponentTitle = (uuid: string) => {
  return store.getters['displayComponent/getComponentTitle'](uuid);
};
const getComponentState = (uuid: string) => {
  return store.getters['displayComponent/getComponentState'](uuid);
};
const getComponentRowDataTrigger = (uuid: string) => {
  return store.getters['displayComponent/getComponentRowDataTrigger'](uuid);
};
const getComponentParentUuid = (uuid: string) => {
  return store.getters['displayComponent/getComponentParentUuid'](uuid);
};

// Setters
const updateComponentTitle = ({
  uuid,
  title,
}: {
  uuid: string;
  title: string;
}) => {
  store.dispatch('displayComponent/updateComponentTitle', { uuid, title });
};
const updateComponentParams = ({ uuid, inParams }) => {
  store.dispatch('displayComponent/updateComponentParams', { uuid, inParams });
};
const updateComponentState = ({ uuid, editQuery, any:rowCount = undefined }) => {
  store.dispatch('displayComponent/updateComponentState', { uuid, editQuery, rowCount });
};

// Computed
const params = computed(() => {
  return getComponentParams(props.uuid).inParams;
});

const title = computed(() => {
  return getComponentTitle(props.uuid);
});

const editQuery = computed(() => {
  return getComponentState(props.uuid).editQuery;
});

const rowDataTrigger = computed(() => {
  return getComponentRowDataTrigger(props.uuid);
});

const parentUuid = computed(() => {
  return getComponentParentUuid(props.uuid);
});

const querySet = computed(() => {
  return new QuerySet(getComponentParams(props.uuid)?.querySet || {});
});

const error = computed(() => {
  return getComponentState(props.uuid).error;
});

const isInvestigationView = computed(() => {
  return String(querySet.value?.menu).startsWith('Investigation');
});

// Methods
const onClickRefreshTitle = function () {
  const qs = querySet.value;
  editTitle.value = qs.buildSummary(editParams.value, editTitle.value || '');
};

const onKeypressListener = function (e) {
  if (e.key === 'Shift') {
    if (e.type === 'keydown') {
      autoExecute.value = false;
    } else if (e.type === 'keyup') {
      autoExecute.value = true;
    }
  }
};

const getFieldWithCache = function (fieldKey) {
  if (!(fieldKey in cacheVariable) && editParams.value) {
    cacheVariable[fieldKey] = editParams.value[fieldKey];
  }
  return cacheVariable[fieldKey];
};

const onClickEditQuery = function () {
  updateComponentState({
    uuid: props.uuid,
    editQuery: true,
  });
};

const onClickCancelEdit = function () {
  updateComponentState({
    uuid: props.uuid,
    editQuery: false,
  });
};

const onClickSaveQuery = async function () {
  if (form.value.validate()) {
    updateComponentTitle({
      uuid: props.uuid,
      title: editTitle.value || '',
    });
    updateComponentParams({
      uuid: props.uuid,
      inParams: { ...editParams.value },
    });
    updateComponentState({
      uuid: props.uuid,
      rowCount: -1,
    });
    await applyQuerySetParams(props.uuid);
    return true;
  }
  eventBus.$emit('show:snackbar', {
    message: 'Unable to save query due to validation errors.',
    color: 'error',
    icon: 'mdi-alert',
  });
  return false;
};

const onClickSaveRunQuery = function () {
  onClickRefreshTitle();
  if (onClickSaveQuery()) {
    onClickRunQuery();
  }
};

const onClickNewQuery = function () {
  createNewQueryComponent('New query');
};

const createQueryTemplate = function ({ title, queryTemplate, params }) {
  const mparams = queryTemplate.mergeParams(params, this.params);
  createNewTemplateQueryComponent(
    title,
    queryTemplate,
    mparams,
    props.uuid,
    autoExecute.value,
  );
};

const onClickRunQuery = function () {
  runQuerySet(props.uuid);
  eventBus.$emit('show:snackbar', {
    message: 'Executing query set...',
  });
};

const onDownloadTimeline = async function () {
  const engagement = this.params?.engagementName;
  const cluster = this.params?.cluster;
  const database = this.params?.database;
  const res = await getTimelineSpreadsheet(engagement, cluster, database);
  const data = Uint8Array.from(Buffer.from(res.data, 'base64'));
  var blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, engagement + '.xlsx');
};

// Watch
watch(editQuery, function (value) {
  //if (value) {
  //  editParams.value = { ...this.params };
  //  editTitle.value = this.title;
  //} else {
  //  editParams.value = null;
  //  editTitle.value = null;
  //}
  //cacheVariable = {};
});

// Mounted
onMounted(() => {
  if (editQuery) {
    editParams.value = { ...params.value };
    editTitle.value = title.value;
  }
  window.addEventListener('keydown', onKeypressListener);
  window.addEventListener('keyup', onKeypressListener);
});
</script>

<style></style>
