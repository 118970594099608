import localforage from 'localforage';

const userSettingsStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  storeName: 'user_settings',
});

const state = () => ({
  userSettings: {},
});

const getters = {
  getUserState: (state) => {
    return state.userSettings;
  },
  getDarkModeState: (state) => {
    return state.userSettings.darkMode;
  },
};

const mutations = {
  setDarkMode(state, isDark) {
    state.userSettings.darkMode = isDark;
  },
};

const actions = {
  async toggleDarkMode({ commit, state }, isDark, reload = false) {
    await userSettingsStore.setItem('darkMode', isDark);
    commit('setDarkMode', isDark);
  },
  async getThemeFromStore({ commit }, reload = false) {
    let isDarkMode: boolean | null =
      await userSettingsStore.getItem('darkMode');
    commit('setDarkMode', isDarkMode);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
