// Functions added to intellisense schema so functions defined
// in mustache don't give errors
const mustacheFunctions = {
  addTaggingFields: {
    Name: "addTaggingFields",
    Body: '{ T | extend HashId="", EventTime=datetime(null), TimestampType="", TagEvent=dynamic(null) }',
    Folder: "",
    FunctionKind: "UnknownFunction",
    DocString: "",
    InputParameters: [
      { Name: "T", Columns: [] },
      {
        Name: "TableName",
        CslType: "string",
        Type: "System.String",
        CslDefaultValue: "''",
      },
    ],
    OutputColumns: [],
  },
};

export const transformSchema = (schema, prettyName) => {
  // Fix JSON schema object so that it uses the pretty name
  const dbs = Object.keys(schema?.Databases);
  if (dbs && dbs.length > 0) {
    const dbName = dbs[0];
    if (dbName !== prettyName) {
      const outSchema = { Databases: {} };
      outSchema.Databases[prettyName] = schema.Databases[dbName];
      outSchema.Databases[prettyName].Name = prettyName;
      schema = outSchema;
    }
    for (const [fname, def] of Object.entries(mustacheFunctions)) {
      schema.Databases[prettyName].Functions[fname] = def;
    }
  }
  return schema;
};
