<template>
  <v-container
    class="fill-height"
    fluid
  >
    <div :class="isTreeOpen ? 'tim-main-view-2' : 'tim-main-view-1'">
      <v-row>
        <v-col>
          <h2>Saved Queries</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            class="ml-1"
            variant="flat"
            disabled
            size="small"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-plus-circle
            </v-icon>
            Create
          </v-btn>
          <v-btn
            class="ml-1"
            variant="flat"
            size="small"
            :disabled="selectedDelete.length === 0"
            @click="onClickDelete"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-delete
            </v-icon>
            Delete ({{ selectedDelete.length }})
          </v-btn>
          <v-btn
            v-if="showDeleted"
            class="ml-1"
            variant="flat"
            size="small"
            :disabled="selectedRestore.length === 0"
            @click="onClickRestore"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-auto-fix
            </v-icon>
            Restore ({{ selectedRestore.length }})
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredQueries"
          :search="search"
          :loading="loading"
          show-select
          item-key="queryId"
        >
          <template v-slot:no-data>No Results</template>
          <template #item.name="{ item }">
            <a
              v-if="item.activeStatus === true"
              href="javascript:void(0)"
              @click="onClickView(item)"
              >{{ item.name }}</a
            >
            <span
              v-else
              class="text-grey-lighten-1"
              >{{ item.name }}</span
            >
          </template>
          <template #top>
            <v-switch
              v-model="showDeleted"
              label="Show deleted"
              :style="{ paddingLeft: '15px' }"
            />
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Filter"
              class="mx-4"
            />
          </template>
        </v-data-table>
      </v-row>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { getAllSavedQueries, toggleSavedQuery } from '@/services/apiClient';
import { createSavedQueryComponent } from '@/renderer/displayComponent';

import { ref, computed, onMounted } from 'vue';

// Data
const savedQueries = ref([]);
const createQueryDialog = ref(false);
const editQuery = ref(null);
const showDeleted = ref(false);
const search = ref(null);
const headers = ref([
  { title: 'Name', key: 'name' },
  { title: 'Last Updated', key: 'updatedDateTimeUtc' },
  { title: 'Cluster', key: 'cluster' },
]);
const loading = ref<boolean>(true);
const selected = ref([]);

// Computed
const filteredQueries = computed(() => {
  if (showDeleted.value) {
    return savedQueries.value;
  } else {
    return savedQueries.value.filter((query) => query.activeStatus === true);
  }
});

const selectedRestore = computed(() => {
  return selected.value.filter((query) => query.activeStatus !== true);
});

const selectedDelete = computed(() => {
  return selected.value.filter((query) => query.activeStatus === true);
});

const isTreeOpen = computed(() => {
  return process.env.VUE_APP_TREE_MODE === 'open';
});

// Methods
const reloadQueries = async function () {
  const result = await getAllSavedQueries();
  savedQueries.value = result.data;
  loading.value = false;
};

const onClickRestore = async function () {
  await Promise.all(
    selectedRestore.value.map((query) => toggleSavedQuery(query.queryId)),
  );
  await reloadQueries();
  selected.value = [];
};

const onClickDelete = async function () {
  await Promise.all(
    selectedDelete.value.map((query) => toggleSavedQuery(query.queryId)),
  );
  await reloadQueries();
  selected.value = [];
};

const onClickView = async function (query) {
  await createSavedQueryComponent(query.name, query.queryId);
};

// Mounted
onMounted(() => {
  reloadQueries();
});
</script>

<style></style>
