import displayComponent from './modules/displayComponent';
import queries from './modules/queries';
import functions from './modules/functions';
import columnViews from '@/store/modules/columnViews';
import engagement from './modules/engagement';
import userSettings from './modules/userSettings';
import {
  ColumnViewsState,
  DisplayComponentState,
  EngagementState,
  FunctionsState,
  QueriesState,
} from './modules/stateInit';
import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

export interface State {
  displayComponent: DisplayComponentState;
  queries: QueriesState;
  functions: FunctionsState;
  columnViews: ColumnViewsState;
  engagement: EngagementState;
  userSettings: Object // Dan - fix with update to Pinia.

}
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    displayComponent: displayComponent,
    queries: queries,
    functions: functions,
    columnViews: columnViews,
    engagement: engagement,
    userSettings: userSettings
  },
  strict: debug,
});

// Expose our wrapper to the actual useStore
export function useStore() {
  return baseUseStore(key);
}

export default store;
