<template>
  <v-container
    class="fill-height"
    fluid
  >
    <div :class="isTreeOpen ? 'tim-main-view-2' : 'tim-main-view-1'">
      <v-row>
        <v-col>
          <h2>Function Editor</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            class="ml-1"
            variant="flat"
            size="small"
            @click="onClickCreateFunction"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-plus-circle
            </v-icon>
            Create
          </v-btn>
          <v-btn
            class="ml-1"
            variant="flat"
            size="small"
            :disabled="selectedDelete.length === 0"
            @click="onClickDelete"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-delete
            </v-icon>
            Delete ({{ selectedDelete.length }})
          </v-btn>
          <v-btn
            v-if="showDeleted"
            class="ml-1"
            variant="flat"
            size="small"
            :disabled="selectedRestore.length === 0"
            @click="onClickRestore"
          >
            <v-icon
              size="small"
              theme="dark"
              start
            >
              mdi-auto-fix
            </v-icon>
            Restore ({{ selectedRestore.length }})
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredFunctions"
          :search="search"
          :loading="loading"
          show-select
          item-value="uuid"
          @toggle-select-all="selectAllToggle"
        >
          <template v-slot:no-data> No Results </template>
          <template #item.name="{ item }">
            <a
              v-if="item.isDeleted !== true"
              href="javascript:void(0)"
              @click="onClickEditFunction(item)"
              >{{ item.name }}</a
            >
            <span
              v-else
              class="lighten-text-grey-1"
              >{{ item.name }}</span
            >
          </template>
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-checkbox-btn
              :value="isSelected"
              :readonly="item.isManaged"
              :disabled="item.isManaged"
              @input="select($event)"
            />
          </template>
          <template #top>
            <v-switch
              v-model="showDeleted"
              label="Show deleted"
              :style="{ paddingLeft: '15px' }"
            />
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Filter"
              class="mx-4"
            />
          </template>
        </v-data-table>
      </v-row>
    </div>
    <FunctionDialog
      v-if="createFunctionDialog"
      v-model:dialog="createFunctionDialog"
      :edit-function="editFunction"
      @reload:functions="onReloadFunctions"
      @update:dialog="onCloseDialog"
    />
  </v-container>
</template>

<script setup lang="ts">
import FunctionDialog from '@/components/FunctionDialog.vue';
import { functionDelete, functionUpdate } from '@/services/apiClient';
import { useStore } from '@/store/store';
import { computed, ref } from 'vue';
// Store
const store = useStore();
// Data
const createFunctionDialog = ref(false);
const editFunction = ref<Object | null>();
const showDeleted = ref(false);
const search = ref(null);
const headers = ref([
  { title: 'Name', key: 'name' },
  { title: 'Uuid', key: 'uuid' },
  { title: 'Description', key: 'description' },
  { title: 'Managed', key: 'isManaged' },
]);
const selected = ref([]);

// Getters
const getFunctions = () => {
  return store.getters['functions/getFunctions'];
};

const getActiveFunctions = () => {
  return store.getters['functions/getActiveFunctions'];
};

// Actions
const reloadFunctions = () => {
  store.dispatch('functions/reloadFunctions');
};
// Computed
const loading = computed(() => {
  if (getFunctions() <= 0) {
    return true;
  } else if (getActiveFunctions() <= 0) {
    return true;
  }
  return false;
});

const filteredFunctions = computed(() => {
  if (showDeleted.value) {
    return getFunctions();
  } else {
    return getActiveFunctions();
  }
});

const selectedDelete = computed(() => {
  return selected.value.filter((func) => func.isDeleted !== true);
});

const selectedRestore = computed(() => {
  return selected.value.filter((func) => func.isDeleted === true);
});

const isTreeOpen = computed(() => {
  return process.env.VUE_APP_TREE_MODE === 'open';
});

// Methods
const onClickCreateFunction = () => {
  editFunction.value = null;
  createFunctionDialog.value = true;
};

const onClickEditFunction = (query) => {
  editFunction.value = { ...query };
  createFunctionDialog.value = true;
};

const onCloseDialog = () => {
  editFunction.value = null;
  createFunctionDialog.value = false;
};

const onReloadFunctions = async () => {
  reloadFunctions();
};

const onClickDelete = async () => {
  await Promise.all(
    selectedDelete.value.map((query) => functionDelete(query.uuid)),
  );
  await onReloadFunctions();
  selected.value = [];
};

const onClickRestore = async () => {
  await Promise.all(
    selectedRestore.value.map((func) =>
      functionUpdate(func.uuid, { ...func, isDeleted: false }),
    ),
  );
  await onReloadFunctions();
  selected.value = [];
};

const selectAllToggle = (props) => {
  const availableItems = props.items.filter((item) => !item.isManaged);
  if (selected.value.length !== availableItems.length) {
    selected.value = availableItems;
  } else {
    selected.value = [];
  }
};

//export default {
//  name: "FunctionEditor",
//  data: () => ({
//    createFunctionDialog: false,
//    editFunction: null,
//    showDeleted: false,
//    search: null,
//    headers: [
//      { text: "Name", value: "name" },
//      { text: "Uuid", value: "uuid" },
//      { text: "Description", value: "description" },
//      { text: "Managed", value: "isManaged" },
//    ],
//    selected: [],
//  }),
//  computed: {
//    filteredFunctions: function () {
//      if (this.showDeleted) {
//        return this.getFunctions();
//      } else {
//        return this.getActiveFunctions();
//      }
//    },
//    selectedDelete: function () {
//      return this.selected.filter((func) => func.isDeleted !== true);
//    },
//    selectedRestore: function () {
//      return this.selected.filter((func) => func.isDeleted === true);
//    },
//    isTreeOpen() {
//      return process.env.VUE_APP_TREE_MODE === "open";
//    },
//  },
//  methods: {
//    ...mapActions("functions", ["reloadFunctions"]),
//    ...mapGetters("functions", ["getFunctions", "getActiveFunctions"]),
//    onClickCreateFunction() {
//      this.editFunction = null;
//      this.createFunctionDialog = true;
//    },
//    onClickEditFunction(query) {
//      this.editFunction = { ...query };
//      this.createFunctionDialog = true;
//    },
//    async onReloadFunctions() {
//      await this.reloadFunctions();
//    },
//    async onClickDelete() {
//      await Promise.all(
//        this.selectedDelete.map((query) => functionDelete(query.uuid)),
//      );
//      await this.onReloadFunctions();
//      this.selected = [];
//    },
//    async onClickRestore() {
//      await Promise.all(
//        this.selectedRestore.map((func) =>
//          functionUpdate(func.uuid, { ...func, isDeleted: false }),
//        ),
//      );
//      await this.onReloadFunctions();
//      this.selected = [];
//    },
//    selectAllToggle(props) {
//      const availableItems = props.items.filter((item) => !item.isManaged);
//      if (this.selected.length !== availableItems.length) {
//        this.selected = availableItems;
//      } else {
//        this.selected = [];
//      }
//    },
//  },
//  components: {
//    FunctionDialog,
//  },
//};
</script>

<style></style>
