<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    :scrim="false"
    :retain-focus="false"
    no-click-animation
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="popup-header">
          <span class="text-h5">Customise Tag {{ objectTypeC }}</span>
        </v-card-title>
        <v-card-subtitle>
          {{ events.length }} event(s) selected
        </v-card-subtitle>
        <v-card-text>
          <v-container
            fluid
            no-gutters
          >
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="description"
                  :label="objectTypeC"
                  :rules="[(v) => !!v || `${objectType} name cannot be empty.`]"
                  :disabled="investigationView && !manualEntryView"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="descriptionCol"
                  :items="columnNames"
                  :disabled="investigationView"
                  @update:model-value="onDescriptionColChange"
                />
              </v-col>
            </v-row>
            <v-row v-if="objectType == 'identity'">
              <v-col class="py-1">
                <v-text-field
                  v-model="objectIdentifier"
                  label="Object Identifier"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="identifierCol"
                  :items="columnNames"
                  @update:model-value="onIdentifierColChange"
                />
              </v-col>
            </v-row>
            <v-row v-if="objectType == 'device'">
              <v-col class="py-1">
                <v-text-field
                  v-model="ipAddress"
                  label="IP Address"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="ipCol"
                  :items="ipColNames"
                  @update:model-value="onIpColChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-select
                  v-model="determination"
                  :items="determinationOptions"
                  :disabled="
                    determinationAction === actions.Ignore ||
                    determinationAction === actions.Remove
                  "
                  :rules="[
                    (v) =>
                      determinationAction === actions.Ignore ||
                      determinationAction === actions.Remove ||
                      !!v ||
                      'Determination cannot be empty.',
                  ]"
                  label="Determination"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="determinationAction"
                  :items="[actions.Ignore, actions.Override, actions.Remove]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="comment"
                  label="Comment"
                  :disabled="commentAction === actions.Ignore"
                  :rules="[
                    (v) =>
                      commentAction === actions.Ignore ||
                      !!v ||
                      'Comment cannot be empty.',
                  ]"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="3"
              >
                <v-select
                  v-model="commentAction"
                  :items="[actions.Ignore, actions.Override, actions.Append]"
                />
              </v-col>
            </v-row>
            <v-row v-if="objectType == 'identity'">
              <v-col class="py-1">
                <v-text-field
                  v-model="remediation"
                  label="Remediation"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="selectedTags"
                  v-model:search="search"
                  :items="tags"
                  chips
                  closable-chips
                  hide-details
                  hide-selected
                  label="Tags"
                  multiple
                  :disabled="tagAction === actions.Ignore"
                >
                  <template
                    v-if="tagAction !== actions.Remove"
                    #prepend-item
                  >
                    <v-list-item
                      ripple
                      :disabled="!search"
                      @mousedown.prevent
                      @click="onClickTagSelect"
                    >
                      <v-list-item-title>
                        {{ search || 'Type to add new tag...' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Custom tag</v-list-item-subtitle>
                    </v-list-item>
                    <v-divider class="mt-2" />
                  </template>
                  <!--<template #item="{ item }">
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item in existingTagsObj">
                      Exists in {{ existingTagsObj[item].length }} event(s)
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="recentTags.includes(item)">
                      Recent tag
                    </v-list-item-subtitle>
                  </template>-->
                </v-autocomplete>
              </v-col>
              <v-col
                class="py-1"
                cols="2"
              >
                <v-select
                  v-model="tagAction"
                  :items="[
                    actions.Ignore,
                    actions.Append,
                    actions.Remove,
                    actions.Override,
                  ]"
                  @update:model-value="onClickChangeTagAction"
                />
              </v-col>
            </v-row>
          </v-container>
          <ul
            v-for="(error, index) in errors"
            :key="`error-${index}`"
            class="text-error"
          >
            <li>{{ error }}</li>
          </ul>
          <ul
            v-for="(modification, index) in modifications"
            :key="`mod-${index}`"
          >
            <li>{{ modification }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="onSaveTagObject()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { eventBus } from '@/main';
import {
  createObjectComments,
  createObjectTags,
  saveObjects as _saveObjects,
  createSavedQuery,
} from '@/services/apiClient';
import {
  Actions,
  presetTags,
  retrieveRecentTags,
  tagsDiff,
  tagsFromData,
  objectDeterminations,
  tagToLabel,
  generateSaveObjectsList,
  generateObjectCommentsList,
  generateObjectTagsList,
  inferIpColumns,
  getObjectMeta,
  updateObjectInvestigationRows,
  updateEventTags as _updateEventTags,
  observableTypes,
  generateDeleteObjectList,
  updateInvEventDet,
} from '@/renderer/tags';

import {
  extractEventId,
  capitaliseFirstLetter,
  createQueryId,
} from '../renderer/utils';
import { mustacheExpand } from '@/renderer/kusto_queries';
import { resolveQuery } from '@/renderer/displayComponent';

import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/store/store';
import { VForm } from 'vuetify/components';

// TODO Dan - properly type annotate this in the future (for all passed
// in arguments of a function type)
let onSuccess;

// Data
const form = ref<VForm>();
const dialog = ref<boolean>(false);
const determination = ref(null);
const determinationOptions = ref(objectDeterminations);
const comment = ref(null);
const search = ref(null);
const recentTags = ref<string[]>([]);
const selectedTags = ref<string[]>([]);
const pinnedTags = ref([]);
const commentAction = ref(Actions.Override);
const determinationAction = ref(Actions.Override);
const tagAction = ref(Actions.Ignore);
const events = ref([]);
const valid = ref<boolean>(true);
const errors = ref<string[]>([]);
const modifications = ref<string[]>([]);
const modifiedEvents = ref([]);
const description = ref('');
const descriptionCol = ref('');
const timeFields = ref({});
const investigationView = ref(false);
const objectType = ref('identity');
const objectTypeC = ref('');
const objectIdentifier = ref('');
const identifierCol = ref('');
const remediation = ref('');
const remediationTimestamp = ref('');
const ipAddress = ref('');
const ipCol = ref('');
const ipColNames = ref<string[]>();
const columnNames = ref([]);
const observableType = ref('');
const observableTypeList = ref(observableTypes);
const observableAction = ref(Actions.Override);
const title = ref('');
const titleAction = ref(Actions.Override);
const manualEntryView = ref(false);
const engagementName = ref('');
const queryUuid = ref('');

// Data
const store = useStore();
// Computed
const ssirpTags = computed(() => {
  return store.getters['engagement/getTopTags'];
});
const tags = computed(() => {
  if (tagAction.value === Actions.Remove) {
    return [...new Set([...selectedTags.value, ...existingTags.value])];
  }
  return [
    ...new Set([
      ...selectedTags.value,
      ...existingTags.value,
      ...recentTags.value,
      ...presetTags().union(new Set(ssirpTags.value)),
    ]),
  ];
});

const existingTags = computed(() => {
  return events.value.map((data) => tagsFromData(data)).flat();
});

const existingTagsObj = computed(() => {
  return events.value.reduce((obj, data) => {
    tagsFromData(data).forEach((tag) => {
      if (!obj[tag]) {
        obj[tag] = [];
      }
      obj[tag].push(data);
    });
    return obj;
  }, {});
});

const actions = computed(() => {
  return Actions;
});

const getEngagement = computed(() => {
  return store.getters['enagement/getEngagement'];
});

const unsavedEvents = computed(() => {
  return events.value.filter((data) => data.TagEvent?.IsSaved !== true);
});

const isTimestampChanged = computed(() => {
  // TODO: Dan - What is eventTimeCol?
  //return eventTimeCol !== timeFields.value?.defaultType;
  return false;
});

// Methods
const onClickTagSelect = function () {
  selectedTags.value = [...new Set([search.value, ...selectedTags.value])];
  search.value = '';
};

const onClickChangeTagAction = function () {
  updateModifications();
};

const onDescriptionColChange = function () {
  description.value = events.value[0][descriptionCol.value];
};

const onIdentifierColChange = function () {
  objectIdentifier.value = events.value[0][identifierCol.value];
};

const onIpColChange = function () {
  ipAddress.value = events.value[0][ipCol.value];
};

const updateModifications = function () {
  modifications.value = [];
  if (selectedTags.value.length === 0) {
    return;
  }

  if (tagAction.value === Actions.Append) {
    selectedTags.value.forEach((tag) => {
      const modCount = events.value.filter(
        (data) => isTimestampChanged.value || !tagsFromData(data).includes(tag),
      );
      if (modCount.length > 0) {
        modifications.value.push(
          `Adding ${tag} to ${modCount.length} event(s).`,
        );
      }
    });
  } else if (tagAction.value === Actions.Remove) {
    selectedTags.value.forEach((tag) => {
      const modCount = events.value.filter((data) =>
        tagsFromData(data).includes(tag),
      );
      if (modCount.length > 0) {
        modifications.value.push(
          `Removing ${tag} from ${modCount.length} event(s).`,
        );
      }
    });
  } else if (tagAction.value === Actions.Override) {
    selectedTags.value.forEach((tag) => {
      const modCount = events.value.filter(
        (data) => isTimestampChanged.value || !tagsFromData(data).includes(tag),
      );
      if (modCount.length > 0) {
        modifications.value.push(
          `Adding ${tag} to ${modCount.length} event(s).`,
        );
      }
    });
    // Remove tags not in tag selection
    const removeTagCounts = events.value.reduce((obj, data) => {
      tagsFromData(data)
        .filter(
          (tag) =>
            !isTimestampChanged.value && !selectedTags.value.includes(tag),
        )
        .forEach((tag) => {
          if (!(tag in obj)) {
            obj[tag] = 0;
          }
          obj[tag]++;
        });
      return obj;
    }, {});
    Object.entries(removeTagCounts).forEach(([tag, count]) => {
      if (count > 0) {
        modifications.value.push(`Removing ${tag} from ${count} event(s).`);
      }
    });
  }
};

const validate = function () {
  errors.value = [];

  if (!description.value) {
    errors.value.push(`${objectType.value} name is missing`);
  }

  if (
    commentAction.value === Actions.Ignore &&
    determinationAction.value === Actions.Ignore &&
    tagAction.value === Actions.Ignore
  ) {
    errors.value.push('At least one action should be selected.');
  }

  if (
    determinationAction.value === Actions.Ignore &&
    unsavedEvents.value.length > 0 &&
    !investigationView.value
  ) {
    errors.value.push(
      `Determination is missing from ${unsavedEvents.value.length} event(s) and cannot be ignored.`,
    );
  }

  if (tagAction.value !== Actions.Ignore && selectedTags.value.length === 0) {
    errors.value.push('Tags cannot be empty.');
  }

  if (
    determinationAction.value === Actions.Remove &&
    (commentAction.value !== Actions.Ignore ||
      tagAction.value !== Actions.Ignore)
  ) {
    errors.value.push(
      'Comment and tag actions must be ignored when removing determination.',
    );
  }

  if (commentAction.value !== Actions.Ignore) {
    try {
      mustacheExpand(comment.value, events.value[0]);
    } catch (error) {
      errors.value.push(error);
    }
  }

  return errors.value.length === 0;
};

const isMultiApplicable = function () {
  // If we are just updating tags, is multi-applicable is always true
  if (commentAction.value !== Actions.Ignore) {
    return true;
  }
  if (
    !descriptionCol.value ||
    description.value !== events.value[0][descriptionCol.value]
  ) {
    return false;
  }
  if (objectType.value === 'device') {
    if (!ipCol.value || ipAddress.value !== events.value[0][ipCol.value]) {
      return false;
    }
  }
  if (objectType.value === 'identity') {
    if (
      !identifierCol.value ||
      objectIdentifier.value !== events.value[0][identifierCol.value]
    ) {
      return false;
    }
  }
  return true;
};

const removeDetermination = async function () {
  try {
    eventBus.$emit('show:snackbar', {
      message: 'Removing object deterimation...',
    });
    const engagement = engagementName.value;
    let _events = events.value;
    const objectIds = [];
    _events.forEach((event) => {
      const objMeta = getObjectMeta(
        event,
        objectType.value,
        descriptionCol.value,
      );
      objMeta.Determination = 'remove';
      if (investigationView.value) {
        updateInvEventDet(event, 'remove');
      }
      const objId =
        objMeta?.AdditionalProps?.ObjectId || engagement + ':' + objMeta?.Value;
      if (objId) {
        objectIds.push(objId);
      }
    });

    //if (!isMultiApplicable()) {
    //  events = events.slice(0, 1);
    //}
    const comments = generateDeleteObjectList(objectIds);
    await createObjectComments(comments, engagement);

    //if (investigationView.value) {
    //  const newEvents = updateObjectInvestigationRows(objectType.value, events, objs, comments, tags);
    //  events = newEvents;
    //}

    onSuccess(_events);

    eventBus.$emit('show:snackbar', {
      message: 'Tag events successfully removed.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    eventBus.$emit('show:snackbar', {
      message: `Removing tag events failed: ${err.toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }

  dialog.value = false;
};

const saveObjects = async function (isMulti) {
  const engagement = engagementName.value;
  let metaF = {}; // For list of objects, stores fields to be added to AdditionalInfo field in ObjectComment. Maps a field from the original tagged row to a property in AdditionalInfo
  let metaV = {}; // For a single tagged object. Sets a property in AddionalInfo in ObjectComment to a value
  if (description.value !== events.value[0][descriptionCol.value]) {
    events.value[0][descriptionCol.value] = description.value;
    isMulti = false;
  }
  let _events = isMulti ? events.value : events.value.slice(0, 1);
  if (objectType.value === 'device') {
    if (ipCol.value && ipAddress.value === _events[0][ipCol.value]) {
      metaF['LocalIpAddress'] = ipCol.value;
    } else if (
      ipAddress.value &&
      (!ipCol.value || ipAddress.value !== _events[0][ipCol.value])
    ) {
      metaV['LocalIpAddress'] = ipAddress.value;
    }
  } else if (objectType.value === 'identity') {
    if (
      identifierCol.value &&
      objectIdentifier.value === _events[0][identifierCol.value]
    ) {
      metaF['ObjectSid'] = identifierCol.value;
    } else if (
      objectIdentifier.value &&
      (!identifierCol.value ||
        objectIdentifier.value !== _events[0][identifierCol.value])
    ) {
      metaV['ObjectSid'] = objectIdentifier.value;
    }
  }
  const newObjs = generateSaveObjectsList(
    _events,
    objectType.value,
    engagement,
    descriptionCol.value,
    metaF,
    metaV,
  );

  if (newObjs.length > 0) {
    const q = await resolveQuery(queryUuid.value, true);
    // QueryId is a md5 hash of the query, so that same query will have same id
    const queryId = createQueryId(q);
    await _saveObjects(newObjs, engagement, queryId);
    // Save query used to create these events
    await createSavedQuery(
      q.query,
      q.cluster,
      q.database,
      [],
      queryUuid.value,
      [],
      queryId,
      false,
    );
  }
  return newObjs;
};

const saveComments = async function (isMulti) {
  const engagement = engagementName.value;
  const _events = isMulti ? events.value : events.value.slice(0, 1);
  const additionalInfo = {};
  if (objectType.value === 'identity') {
    additionalInfo['remediation'] = remediation.value;
    additionalInfo['remediationTimestamp'] = new Date().toISOString();
  }
  const comments = generateObjectCommentsList(
    _events,
    engagement,
    descriptionCol.value,
    comment.value,
    determination.value,
    objectType.value,
    commentAction.value,
    determinationAction.value,
    additionalInfo.value,
  );
  await createObjectComments(comments, engagement);
  return comments;
};

const addTags = async function (isMulti) {
  const _events = isMulti ? events.value : events.value.slice(0, 1);
  const addTagList = generateObjectTagsList(
    _events,
    engagementName.value,
    selectedTags.value,
    descriptionCol.value,
  );
  await createObjectTags(addTagList, engagementName.value);
  return addTagList;
};

const removeTags = async function (isMulti) {
  const _events = isMulti ? events.value : events.value.slice(0, 1);
  const removeTagList = generateObjectTagsList(
    _events,
    engagementName.value,
    selectedTags.value,
    descriptionCol.value,
    true,
  );
  await createObjectTags(removeTagList, engagementName.value);
  // TODO - get delta
  return [];
};

const setTags = async function () {
  let modifyTags = [];
  const newEvents = events.map((data) => {
    const addTags = this.isTimestampChanged
      ? selectedTags
      : tagsDiff(selectedTags, tagsFromData(data));
    const removeTags = this.isTimestampChanged
      ? []
      : tagsDiff(tagsFromData(data), selectedTags);

    modifyTags.push(
      ...addTags.map((tag) => ({
        eventId: extractEventId(data._id),
        timestampType: this.eventTimeCol,
        tag: tag,
        isDeleted: false,
      })),
    );
    modifyTags.push(
      ...removeTags.map((tag) => ({
        eventId: extractEventId(data._id),
        timestampType: this.eventTimeCol,
        tag: tag,
        isDeleted: true,
      })),
    );

    return {
      ...data,
      TagEvent: {
        ...data.TagEvent,
        Tags: selectedTags,
      },
    };
  });
  await createObjectTags(modifyTags, engagementName.value);
  events.value = newEvents;
};

const updateEventTags = (events, objs, comments, tags) => {
  const newEvents = [];
  for (let i = 0; i < events.length; i++) {
    let objTags = events[i]?.TagEvent[objectType.value];
    if (!objTags) {
      objTags = events[i].TagEvent[objectType.value] = [
        { Value: description.value },
      ];
    }
    let objIdx = objTags.length;
    for (let j = 0; j < objTags.length; j++) {
      if (objTags[j]?.Value == description.value) {
        objIdx = j;
      }
    }
    let ot = objTags[objIdx];
    ot.Determination = comments[i].determination;
    ot.Column = descriptionCol.value;
    newEvents.push(events[i]);
  }
  return newEvents;
};

const onSaveTagObject = async () => {
  const formValid = await form.value?.validate().then((form) => form.valid);
  if (!formValid || !validate()) {
    return false;
  }

  const isMulti = isMultiApplicable();

  if (determinationAction.value === Actions.Remove) {
    return await removeDetermination();
  }

  eventBus.$emit('show:snackbar', {
    message: 'Customising tag objects...',
  });

  try {
    let objs = [];
    let comments = [];
    let tags = [];
    if (determinationAction.value === Actions.Override) {
      objs = await saveObjects(isMulti);
    }

    if (
      determinationAction.value !== Actions.Ignore ||
      commentAction.value !== Actions.Ignore
    ) {
      comments = await saveComments(isMulti);
    }

    if (
      tagAction.value === Actions.Append ||
      tagAction.value === Actions.Override
    ) {
      // TODO: implement set tags
      tags = await addTags(isMulti);
    } else if (tagAction.value === Actions.Remove) {
      tags = await removeTags(isMulti);
    }
    //const newEvents = updateEventTags(events, objs, comments, tags);
    let newEvents = [];
    if (investigationView.value) {
      newEvents = updateObjectInvestigationRows(
        objectType.value,
        events,
        objs,
        comments,
        tags,
      );
    } else {
      newEvents = _updateEventTags(
        events,
        objs,
        comments,
        tags,
        objectType.value,
        descriptionCol.value,
      );
    }

    onSuccess(newEvents);

    determination.value = null;
    comment.value = null;
    selectedTags.value = [];

    eventBus.$emit('show:snackbar', {
      message: 'Tag events successfully customised.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (err) {
    eventBus.$emit('show:snackbar', {
      message: `Customisation of tag events failed: ${err.toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  }

  dialog.value = false;
};

// Watch
watch(selectedTags, function () {
  updateModifications();
});

onBeforeMount(() => {
  eventBus.$on(
    'create:tag-object-dialog',
    async ({
      events: _events,
      onSuccess: _onSuccess,
      objectType: _objectType,
      colName: _colName,
      tags: _tags,
      investigationView: _investigationView,
      manualEntryView: _manualEntryView,
      engagementName: _engagementName,
      queryUuid: _queryUuid,
    }) => {
      if (!_events) {
        eventBus.$emit('show:snackbar', {
          message: 'Identity not found.',
          color: 'error',
          icon: 'mdi-alert',
        });
        return;
      }
      objectType.value = _objectType;
      objectTypeC.value = capitaliseFirstLetter(objectType.value);
      dialog.value = true;
      events.value = _events;
      onSuccess = _onSuccess;
      investigationView.value = _investigationView;
      descriptionCol.value = _colName;
      ipAddress.value = '';
      manualEntryView.value = _manualEntryView;
      engagementName.value = _engagementName || getEngagement.value;
      queryUuid.value = _queryUuid;
      // TODO: check events is not empty
      const event0 = events.value[0];
      columnNames.value = Object.keys(event0);
      description.value = event0[_colName] || '';
      if (engagementName.value) {
        selectedTags.value = _tags;
        tagAction.value = Actions.Append;
      } else {
        selectedTags.value = [];
        tagAction.value = Actions.Ignore;
      }
      // Get IP cols
      if (objectType.value === 'device') {
        ipColNames.value = inferIpColumns(columnNames.value, event0);
        if (ipColNames.value.length > 0) {
          ipCol.value = ipColNames.value[0];
          ipAddress.value = event0[ipCol.value];
        }
        if (!description.value) {
          if (columnNames.value.includes('DeviceDnsName')) {
            descriptionCol.value = 'DeviceDnsName';
            description.value = event0['DeviceDnsName'];
          } else if (columnNames.value.includes('DestinationEntity')) {
            descriptionCol.value = 'DestinationEntity';
            description.value = event0['DestinationEntity'];
          }
        }
      }
      // Get Object identifier col
      else if (objectType.value === 'identity') {
        const idColNames = columnNames.value.filter(
          (c) => event0[c] && event0[c].toString().startsWith('S-'),
        );
        if (idColNames.length > 0) {
          identifierCol.value = idColNames[0];
          objectIdentifier.value = event0[identifierCol.value];
        }
      }
      //const objMeta = getObjectMeta(event0, description, objectType);
      const objMeta = getObjectMeta(event0, objectType.value, _colName);
      if (objMeta) {
        determination.value = tagToLabel(objMeta?.Determination);
        comment.value = objMeta?.Comment;
        if (objectType.value == 'device') {
          const _ip = objMeta?.AdditionalProps?.IpAddress;
          if (ipAddress !== _ip) {
            ipAddress.value = _ip;
            ipCol.value = '';
          }
        } else if (objectType.value == 'identity') {
          remediation.value = objMeta?.AdditionalProps?.Remediation;
          const _sid = objMeta?.AdditionalProps?.ObjectSid;
          if (objectIdentifier.value !== _sid) {
            objectIdentifier.value = _sid;
            ipCol.value = '';
          }
        }
      }
      errors.value = [];
      modifications.value = [];
      recentTags.value = await retrieveRecentTags();
    },
  );
});
</script>
