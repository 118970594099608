export const retrieveColumns = (events) => {
  const columns = Object.values(events).reduce((obj, e) => {
    Object.keys(e.data).forEach((k) => {
      obj.add(k);
    });
    return obj;
  }, new Set());
  return [...columns];
};

export const createMenuContext = (
  items,
  params,
  ignoreCondition = false,
  queryName = '',
) => {
  let menu = [];
  let lookup = {};

  items.forEach((item) => {
    if (typeof item === 'string') {
      menu.push(item);
      return;
    }

    let obj = {
      name: item.name,
      action: () => {
        return item.action(params);
      },
      disabled:
        !ignoreCondition &&
        typeof item.condition !== 'undefined' &&
        !item.condition(params),
      checked: typeof item.checked !== 'undefined' && item.checked(params),
    };

    if (typeof item.path === 'undefined') {
      menu.push(obj);
      return;
    }

    let parentMenu = menu;
    let subMenuKey = 'root';
    item.path.forEach((subMenuName) => {
      subMenuKey = `${subMenuKey}-${subMenuName}`;
      if (!(subMenuKey in lookup)) {
        lookup[subMenuKey] = {
          name: subMenuName,
          subMenu: [],
        };
        parentMenu.push(lookup[subMenuKey]);
      }
      parentMenu = lookup[subMenuKey].subMenu;
    });

    if (parentMenu) {
      parentMenu.push(obj);
    }
  });
  return menu;
};
