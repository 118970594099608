<template>
  <v-container v-if="error">
    <v-alert
      border="start"
      type="error"
      text
    >
      {{ error }}
    </v-alert>
  </v-container>
</template>

<script setup lang="ts">
import { mapGetters } from "vuex";
import { createNewQueryComponent } from "@/renderer/displayComponent";
import { dartFunctions } from "@/renderer/kusto_queries";
import { eventBus } from "@/services/eventBus";
import { QueryTemplate } from "@/renderer/kusto_queries";

import { ref, computed, onMounted } from 'vue';


// Data
const error = ref(null);

// Props
const props = defineProps({
    id: {
      type: String,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
    col: {
      type: String,
      required: true,
    },
    cluster: {
      type: String,
      required: true,
    },
    database: {
      type: String,
      required: true,
    },
  });

// Computed

// Mounted
onMounted(() => {
    const title = props.table;
    const e = this.$route.query?.e;
    const timeField = this.$route.query?.t;
    const ago = this.$route.query?.ago;
    const engagement = e ? e : this.getEngagement;
    const srcTag =
      engagement === props.database
        ? props.table
        : [props.cluster, props.database, props.table].join("/");
    const tags = [
      "engagement:" + engagement,
      "cluster:" + props.cluster,
      "database:" + props.database,
      "source:" + srcTag,
    ];
    const extendEventTime = timeField
      ? ` | extend EventTime = ${timeField}, TimestampType='${timeField}'`
      : "";
    const agoClause =
      ago && timeField ? ` | where ${timeField} > ago(${ago})` : "";
    const eventId = [srcTag, props.id].join(":");
    const query = `${dartFunctions}\ncluster('${props.cluster}').database('${props.database}').${props.table}${agoClause} | where ${props.col} == '${props.id}'${extendEventTime} | extend HashId = column_ifexists('HashId', '${eventId}') | invoke addTaggingFields('${props.table}')`;
    const queryTemplate = new QueryTemplate({
      query: query,
      functions: [],
    });
    const mQuery = queryTemplate.buildQuery({ columnId: props.col });
    const uuid = createNewQueryComponent(
      title,
      mQuery,
      props.cluster,
      props.database,
      [],
      true,
    );
    eventBus.$emit("action:tag-all", { uuid: uuid, tags: tags });
  })
//export default {
//  name: "TagQuery",
//  props: {
//    id: {
//      type: String,
//      required: true,
//    },
//    table: {
//      type: String,
//      required: true,
//    },
//    col: {
//      type: String,
//      required: true,
//    },
//    cluster: {
//      type: String,
//      required: true,
//    },
//    database: {
//      type: String,
//      required: true,
//    },
//  },
//  data: () => ({
//    error: null,
//  }),
//  computed: {
//    ...mapGetters("queries", ["getTemplate", "getQueryTemplates"]),
//    ...mapGetters("engagement", ["getCluster", "getDatabase", "getEngagement"]),
//  },
//  async mounted() {
//    const title = this.table;
//    const e = this.$route.query?.e;
//    const timeField = this.$route.query?.t;
//    const ago = this.$route.query?.ago;
//    const engagement = e ? e : this.getEngagement;
//    const srcTag =
//      engagement === this.database
//        ? this.table
//        : [this.cluster, this.database, this.table].join("/");
//    const tags = [
//      "engagement:" + engagement,
//      "cluster:" + this.cluster,
//      "database:" + this.database,
//      "source:" + srcTag,
//    ];
//    const extendEventTime = timeField
//      ? ` | extend EventTime = ${timeField}, TimestampType='${timeField}'`
//      : "";
//    const agoClause =
//      ago && timeField ? ` | where ${timeField} > ago(${ago})` : "";
//    const eventId = [srcTag, this.id].join(":");
//    const query = `${dartFunctions}\ncluster('${this.cluster}').database('${this.database}').${this.table}${agoClause} | where ${this.col} == '${this.id}'${extendEventTime} | extend HashId = column_ifexists('HashId', '${eventId}') | invoke addTaggingFields('${this.table}')`;
//    const queryTemplate = new QueryTemplate({
//      query: query,
//      functions: [],
//    });
//    const mQuery = queryTemplate.buildQuery({ columnId: this.col });
//    const uuid = await createNewQueryComponent(
//      title,
//      mQuery,
//      this.cluster,
//      this.database,
//      [],
//      true,
//    );
//    eventBus.$emit("action:tag-all", { uuid: uuid, tags: tags });
//  },
//};
</script>

<style></style>
