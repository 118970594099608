import { QueryTemplate, QuerySet } from '@/renderer/kusto_queries';
import { queryRetrieve, querySetRetrieve } from '@/services/apiClient';
import { reactive } from 'vue';

import localforage from 'localforage';
import { queriesActions, queriesGetters, queriesSetters } from './stateInit';

const queryOptionStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  storeName: 'query_options',
});

const state = reactive({
  templates: [],
  queryPromise: null,
  queryOptions: {},
  querySets: [],
});

// Compares path and menu name of 2 query templates
const cmpMenuPath = (a, b) => {
  const apath = [...a.path, a.menu];
  const bpath = [...b.path, b.menu];
  const apathstr = apath.join('/');
  const bpathstr = bpath.join('/');
  return apathstr.localeCompare(bpathstr);
};

const getters: queriesGetters = {
  getQueryTemplates: (state) => {
    return state.templates
      .filter((query) => query.queryType === 'query')
      .sort((a, b) => cmpMenuPath(a, b));
  },
  getViewTemplates: (state) => {
    return state.templates
      .filter((query) => query.queryType === 'view')
      .sort((a, b) => cmpMenuPath(a, b));
  },
  getAllTemplates: (state) => {
    return state.templates.filter(() => true).sort((a, b) => cmpMenuPath(a, b));
  },
  getTemplatesByClass: (state) => (queryClass) => {
    const ts = state.templates
      .filter((query) =>
        query?.queryClass ? query?.queryClass.includes(queryClass) : false,
      )
      .sort((a, b) => cmpMenuPath(a, b));
    return ts;
  },
  getUserTemplates: (state) => {
    const ts = state.templates
      .filter(
        (query) =>
          query?.isDeleted !== true &&
          query?.isManaged !== true &&
          (query?.queryClass ? query.queryClass.length == 0 : true),
      )
      .sort((a, b) => cmpMenuPath(a, b));
    return ts;
  },
  getManagedTemplates: (state) => (excludeClasses) => {
    const excludeSet = new Set(excludeClasses);
    const ts = state.templates
      .filter((query) => query?.isDeleted !== true && query?.isManaged === true && (new Set(query?.queryClass).intersection(excludeSet).size === 0))
      .sort((a, b) => cmpMenuPath(a, b));
    return ts;
  },
  getTemplate: (state) => (uuid) => {
    return state.templates.find((query) => query.uuid === uuid);
  },
  getQueryOption: (state) => (uuid) => {
    return state.queryOptions[uuid] ?? {};
  },
  getTemplateByName: (state) => (name) => {
    return state.templates.find((query) => query.menu === name);
  },
  getAllQuerySets: (state) => {
    return state.querySets;
  },
  getQuerySet: (state) => (uuid) => {
    return state.querySets.find((qs) => qs.uuid === uuid);
  },
  getInvestigationQuerySet: (state) => {
    return state.querySets.find((qs) => qs.menu === 'Investigation Views');
  },
};

const actions: queriesActions = {
  async getQueries({ commit, state }, reload = false) {
   if (!state.queryPromise || reload) {
      commit('setQueryPromise', queryRetrieve());
    }

    return state.queryPromise;
  },
  async getQuerySets({ commit, state }, reload = false) {
    if (!state.querySetsPromise || reload) {
      commit('setQuerySetsPromise', querySetRetrieve());
    }

    return state.querySetsPromise;
  },
  async loadQueries({ commit, dispatch, rootGetters }, reload = false) {
    const queries = await dispatch('getQueries', reload);
    await dispatch('functions/loadFunctions', null, { root: true });

    const templates = queries
      .filter((query) => query.isDeleted !== true)
      .map((query) => {
        const functions = (query.functions ?? []).map((uuid) =>
          rootGetters['functions/getFunctionByUuid'](uuid),
        );
        return new QueryTemplate({ ...query, functions });
      });

    const queryOptions = {};
    await queryOptionStore.iterate((queryOption, uuid) => {
      queryOptions[uuid] = queryOption;
    });

    const qs = await dispatch('getQuerySets', reload);
    const querySets = qs
      .filter((qs) => qs.isDeleted !== true)
      .map((qs) => new QuerySet(qs));

    commit('setTemplates', templates);
    commit('setQueryOptions', queryOptions);
    commit('setQuerySets', querySets);
  },
  async reloadQueries({ dispatch }) {
    await dispatch('loadQueries', true);
  },
  addQuery({ commit, state }, query) {
    const template = new QueryTemplate(query);
    const templates = [...state.templates, template];
    commit('setTemplates', templates);
  },
  updateQuery({ commit, state }, query) {
    const template = new QueryTemplate(query);
    const templates = [
      ...state.templates.filter((q) => q.uuid !== query.uuid),
      template,
    ];
    commit('setTemplates', templates);
  },
  removeQuery({ commit, state }, uuid) {
    const templates = [...state.templates.filter((q) => q.uuid !== uuid)];
    commit('setTemplates', templates);
  },
  async updateQueryOption({ commit, state }, { uuid, queryOption }) {
    const prevQueryOption = state.queryOptions[uuid] ?? {};
    const newQueryOption = { ...prevQueryOption, ...queryOption };

    await queryOptionStore.setItem(uuid, newQueryOption);

    commit('setQueryOption', { uuid: uuid, queryOption: newQueryOption });
  },
};

const mutations: queriesSetters = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setQueryPromise(state, queryPromise) {
    state.queryPromise = queryPromise;
  },
  setQuerySets(state, querySets) {
    state.querySets = querySets;
  },
  setQuerySetsPromise(state, querySetsPromise) {
    state.querySetsPromise = querySetsPromise;
  },
  setQueryOptions(state, queryOptions) {
    state.queryOptions = queryOptions;
  },
  setQueryOption(state, { uuid, queryOption }) {
    state.queryOptions[uuid] = queryOption;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
