<template>
  <v-row>
    <v-col class="py-1">
      <v-autocomplete
        v-model:search="search"
        v-model="selectedValue"
        :items="getAllColumnViews"
        density="compact"
        hide-details
        label="Column view"
        :selectedUuid="selectedUuid"
        item-value="uuid"
        item-title="name"
        variant="solo-filled"
        @update:model-value="onSelectView"
      >
        <template #prepend-item>
          <v-list-item
            ripple
            @mousedown.prevent
            @click="onSelectCreateView"
            :disabled="search === ''"
          >
            <v-list-item-title>
              {{ search || 'Type to add new column view...' }}
            </v-list-item-title>
            <v-list-item-subtitle>Create column view</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="mt-2" />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col
      class="py-1"
      md="auto"
    >
      <v-btn
        icon
        variant="flat"
        size="small"
        title="Apply column view"
        :disabled="selectedUuid === null"
        @click="onClickApplyView"
      >
        <v-icon size="small"> mdi-clipboard-check-outline </v-icon>
      </v-btn>
      <v-dialog
        v-model="renameDialog"
        width="640"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Rename column view</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="renameName"
              label="Rename column view"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue-darken-1"
              variant="flat"
              @click="renameDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue-darken-1"
              variant="flat"
              @click="onConfirmRenameView"
            >
              Rename
            </v-btn>
          </v-card-actions>
        </v-card>
        <template #activator="{ props }">
          <v-btn
            icon
            size="small"
            variant="flat"
            title="Rename column view"
            :disabled="selectedUuid === null"
            v-bind="props"
            @click="onClickRenameView"
          >
            <v-icon size="small"> mdi-pencil </v-icon>
          </v-btn>
        </template>
      </v-dialog>
      <v-dialog
        v-model="removeDialog"
        width="640"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Delete column view</span>
          </v-card-title>
          <v-card-text>
            Are you should you wish to delete column view "{{ selectedName }}"?
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue-darken-1"
              variant="flat"
              @click="removeDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue-darken-1"
              variant="flat"
              @click="onConfirmRemoveView"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
        <template #activator="{ props }">
          <v-btn
            icon
            size="small"
            variant="flat"
            title="Delete column view"
            :disabled="selectedUuid === null"
            v-bind="props"
          >
            <v-icon size="small"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-dialog>
      <v-btn
        icon
        size="small"
        variant="flat"
        title="Save this column view"
        :disabled="selectedUuid === null || search === ''"
        @click="onClickSaveView"
      >
        <v-icon size="small"> mdi-content-save </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { eventBus } from '@/main';
import { useStore } from '@/store/store';
import { computed } from '@vue/reactivity';
import { ref } from 'vue';
// Store
const store = useStore();
// Data
const search = ref<string>();
const selectedValue = ref<string>();
const selectedUuid = ref<string>();
const renameDialog = ref<boolean>(false);
const renameName = ref<string>('');
const removeDialog = ref<boolean>(false);
// Computed Functions
const getAllColumnViews = computed(() => {
  return store.getters['columnViews/getAllColumnViews'];
});
const getColumnView = (uuid: string) => {
  return store.getters['columnViews/getColumnView'](uuid);
};
const selected = () => {
  if (props.selectedUuid !== null) {
    return getColumnView(props.selectedUuid);
  }
  return null;
};
const selectedName = computed(() => {
  return selected()?.name;
});
// Props
const props = defineProps<{
  selectedUuid: String;
}>();
// Emitters
const emit = defineEmits<{
  update: [selectedUuid: string];
  'add-column-view': [search: string];
  'save-column-view': [];
  'apply-column-view': [];
}>();
// Actions
const updateColumnView = (viewObj) => {
  return store.dispatch('columnViews/updateColumnView', viewObj);
};
const removeColumnView = (uuid: string) => {
  return store.dispatch('columnViews/removeColumnView', uuid);
};
const onClickSaveView = () => {
  emit('save-column-view');
};
const onClickApplyView = () => {
  emit('apply-column-view');
};
const onConfirmRemoveView = async () => {
  await removeColumnView(props.selectedUuid);
  eventBus.$emit('update:selectedUuid', null);
  removeDialog.value = false;
};
const onClickRenameView = () => {
  renameName.value = selectedName.value;
};
const onConfirmRenameView = async () => {
  await updateColumnView({
    uuid: props.selectedUuid,
    name: renameName.value,
  });
  renameDialog.value = false;
};
const onSelectView = (uuid: string) => {
  eventBus.$emit('update:selectedUuid', uuid);
};
const onSelectCreateView = async () => {
  if (search.value) {
    emit('add-column-view', search.value.trim());
  }
};
// Method
</script>
