<template>
  <v-skeleton-loader
    :loading="!availableFunctions.length > 0"
    type="paragraph"
  >
    <v-combobox
      label="Functions"
      :items="availableFunctions"
      v-model="functions"
      item-value="name"
      item-title="name"
      variant="outlined"
      multiple
      clearable
      chips
      @update:model-value="emit('input', $event)"
    >
      <!--
    <template #item="{ item, index, props }">
      <v-chip
        :key="item.value.uuid"
        :model-value="item.value.name"
        size="small"
      >
        <template v-slot:prepend>
          <v-avatar
            class="bg-accent text-uppercase"
            start
            >{{ item.value.name.slice(0, 1) }}</v-avatar
          >
        </template>
        {{ item.value.name }}
      </v-chip>
      <v-list-item
        v-bind="props"
        v-on="index"
      >
        <v-icon>
          {{
            selectFunctionHasUuid(item.value.uuid)
              ? 'mdi-checkbox-marked'
              : 'mdi-checkbox-blank-outline'
          }}
        </v-icon>

        <v-list-item-title class="text-left">
          {{ item.value.name }}
          <span v-if="item.value.isDeleted">(deleted)</span>
        </v-list-item-title>
        <v-list-item-subtitle>{{ item.value.description }}</v-list-item-subtitle>
      </v-list-item>
    </template>
      -->
    </v-combobox>
  </v-skeleton-loader>
</template>

<script setup lang="ts">
import { KustoFunction } from '@/services/models';
import { useStore } from '@/store/store';
import { computed, onMounted, PropType, ref } from 'vue';

// Store
const store = useStore();
// Data
const functions = ref({});
// Props
const props = defineProps({
  functions: {
    type: Array as PropType<KustoFunction[]>,
    required: true,
  },
});
// Emits
const emit = defineEmits<{
  input: [value: string[]];
}>();
// Getters
const getFunctions = computed(() => {
  return store.getters['functions/getFunctions'];
});
// Actions
const loadFunctions = async () => {
  await store.dispatch('functions/loadFunctions');
};
// Computed
const availableFunctions = computed<KustoFunction[]>(() => {
  return getFunctions.value
    .filter(
      (func: KustoFunction) =>
        !func.isDeleted || selectFunctionHasUuid(func.uuid),
    )
    .sort((a: KustoFunction, b: KustoFunction) => a.name.localeCompare(b.name));
});

// Methods
const selectFunctionHasUuid = (uuid: string) => {
  return (
    props.functions?.some((func: KustoFunction) => func.uuid === uuid) === true
  );
};

// Mounted
onMounted(() => {
  functions.value = props.functions;
});
//export default {
//  name: "FunctionSelection",
//  model: {
//    prop: "functions",
//    event: "input",
//  },
//  props: {
//    functions: {
//      type: Array as PropType<KustoFunction[]>,
//      required: true,
//      default: () => [],
//    },
//  },
//  emits: ["input"],
//  computed: {
//    ...mapGetters("functions", ["getFunctions"]),
//
//    availableFunctions() {
//      return this.getFunctions
//        .filter(
//          (func) => !func.isDeleted || this.selectFunctionHasUuid(func.uuid),
//        )
//        .sort((a, b) => a.name.localeCompare(b.name));
//    },
//  },
//  async mounted() {
//    await this.loadFunctions();
//  },
//  methods: {
//    ...mapActions("functions", ["loadFunctions"]),
//  },
//};
</script>
